<template>
  <section class="setter">
    <div class="img-left">
      <p></p>
      <v-img cover src="https://s3.amazonaws.com/assets.pilsala.com/assets/asian_woman.jpg"></v-img>
    </div>
    <div class="div-rigth" v-show="registerData">
      <section class="section-rigth">
        <div>
          <v-img cover :src="dynamicImageUrl" class="max-w-300"></v-img>
        </div>
        <p class="p-text">
          <span>Completa los datos para</span>
          <span>registrar tu cuenta</span>
        </p>
        <section class="d-flex flex-column">
          <div class="d-flex-2">
            <div class="w-50">
              <p class="p13">¿Cuál es tu nombre?</p>
              <ValidationProvider
                name="nombre"
                rules="required"
                v-slot="{ errors }"
              >
                <v-text-field
                  outlined
                  label="Nombre"
                  v-model="name"
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
            </div>
            <div class="ml-5 w-50">
              <p class="p13">¿Cuál es tu apellido?</p>
              <ValidationProvider
                name="apellido"
                rules="required"
                v-slot="{ errors }"
              >
                <v-text-field
                  outlined
                  full-width
                  label="Apellido"
                  v-model="last_name"
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
            </div>
          </div>
          <div>
            <p class="p13">¿Cómo te gustaría que te diga?</p>
            <ValidationProvider
              name="apodo"
              rules="required"
              v-slot="{ errors }"
            >
              <v-text-field
                class="mt-3"
                outlined
                v-model="nickname"
                :error-messages="errors"
              ></v-text-field>
            </ValidationProvider>
          </div>
          <div class="d-flex-2">
            <div>
              <p class="p13">País (Prefijo)</p>
              <country-autocomplete @dialog:change="HandlerCountryResponse" />
            </div>
            <div class="ml-5 w-100">
              <p class="p13">Whastapp</p>
              <ValidationProvider
                name="telefono"
                rules="required|numeric"
                v-slot="{ errors }"
              >
                <v-text-field
                  v-model="phone_number"
                  class="mt-3 ml-auto"
                  outlined
                  label="Whastapp"
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
            </div>
          </div>
          <div class="mt--2">
            <p class="p13">Crear contraseña</p>
            <ValidationProvider
              name="contraseña"
              rules="required|min:6"
              v-slot="{ errors }"
            >
              <v-text-field
                outlined
                label="Contraseña"
                v-model="password"
                type="password"
                :error-messages="errors"
              ></v-text-field>
            </ValidationProvider>
          </div>
          <div class="d-flex center">
            <v-checkbox
              v-model="checkbox"
              value="1"
              type="checkbox"
              required
            ></v-checkbox>
            <div class="conditionsAndTermins">
              <span>
                <span>Al continuar aceptas nuestros </span>
                <a href="#" @click.prevent="HandlerShowConditions"
                  >términos y condiciones y políticas de privacidad de web</a
                >
                <span> y </span>
                <a href="#" @click.prevent="HandlerShowConditions"
                  >políticas de privacidad</a
                >
                <span> de web</span>
              </span>
              <span> </span>
            </div>
          </div>
          <v-btn
            :disabled="isValid"
            depressed
            class="white--text font-light py-5 text-capitalize mt-10"
            rounded
            color="#101820"
            :loading="loading"
            @click="HandlerRegister"
          >
            Continuar
          </v-btn>

          <v-btn
            color=""
            rounded
            depressed
            class="py-5 text-capitalize mt-5 mb-20"
            @click="HandlerRoute('Login')"
          >
            ¿Ya tienes cuenta?
          </v-btn>
        </section>
       
      </section>

      <recovery-password
        v-if="forgotData"
        :active="forgotData"
        @dialog:change="HandlerResponse"
      />
    </div>
    <experience :registerData="registerInfo" v-show="!registerData" />
  </section>
  <!-- <v-responsive>
    <toolbar-core />
    <v-container>
      <v-row justify="center">
        <v-col cols="12" class="pt-0">
          <v-btn
            @click="HandlerGoBack"
            color="#0CAAC8"
            text
            rounded
            class="text-sub-title text-capitalize mt-5 mt-md-0"
          >
            <v-icon large class="mr-3">mdi-arrow-left-drop-circle</v-icon>
            Volver
          </v-btn>
        </v-col>
        <v-col cols="12" sm="6" :md="registerData ? '6' : '7'">
          <ValidationObserver ref="obs" v-slot="{ passes }">
            <v-card v-show="registerData" class="mb-15 card-custom">
              <div class="py-10 px-10">
                <v-row>
                  <v-col cols="12" class="mb-5">
                    <p class="text-sub-title text-center title-card">
                      Regístrate
                    </p>
                    <div class="d-flex justify-space-around">
                      <span>Disfruta de 7 días gratis</span>
                    </div>
                  </v-col>
                  <v-col cols="12" md="12">
                    <small>¿Cuál es tu nombre? *</small>
                    <ValidationProvider
                      name="nombre"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        class="mt-3"
                        solo
                        v-model="name"
                        :error-messages="errors"
                      ></v-text-field>
                    </ValidationProvider>

                    <small>¿Y tu apellido? *</small>
                    <ValidationProvider
                      name="apellido"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        class="mt-3"
                        solo
                        v-model="last_name"
                        :error-messages="errors"
                      ></v-text-field>
                    </ValidationProvider>

                    <small>¿Cómo te gustaría que te diga? *</small>
                    <ValidationProvider
                      name="apodo"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        class="mt-3"
                        solo
                        v-model="nickname"
                        :error-messages="errors"
                      ></v-text-field>
                    </ValidationProvider>

                    <small>¿A qué WhatsApp te escribo?</small>
                    <v-row>
                      <v-col cols="5" sm="4" class="pr-0">
                        <country-autocomplete
                          @dialog:change="HandlerCountryResponse"
                        />
                      </v-col>
                      <v-col cols="7" sm="8" class="pl-0">
                        <ValidationProvider
                          name="telefono"
                          rules="required|numeric"
                          v-slot="{ errors }"
                        >
                          <v-text-field
                            class="mt-3"
                            solo
                            v-model="phone_number"
                            :error-messages="errors"
                          ></v-text-field>
                        </ValidationProvider>
                      </v-col>
                    </v-row>

                    <small>Contraseña</small>
                    <ValidationProvider
                      name="contraseña"
                      rules="required|min:6"
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        class="mt-3"
                        solo
                        v-model="password"
                        type="password"
                        :error-messages="errors"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12" class="d-flex">
                    <ValidationProvider
                      v-slot="{ errors }"
                      rules="required"
                      name="condiciones"
                    >
                      <div class="d-flex">
                        <v-checkbox
                          v-model="checkbox"
                          value="1"
                          type="checkbox"
                          required
                        ></v-checkbox>
                        <div>
                          Al registrarte aceptas nuestros
                          <a href="#" @click.prevent="HandlerShowConditions"
                            >términos y condiciones y políticas de privacidad de
                            web</a
                          >
                        </div>
                      </div>
                      <span
                        style="color: #ff7373; font-size: 12.5px"
                        class="mt-2"
                        >{{ errors[0] }}</span
                      >
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12" class="text-center">
                    <v-btn
                      :disabled="isValid"
                      color="#0CAAC8"
                      rounded
                      class="white--text font-light"
                      :loading="loading"
                      @click="passes(HandlerRegister)"
                    >
                      Siguiente
                    </v-btn>
                  </v-col>
                  <v-col cols="12" class="text-center">
                    <v-btn
                      @click="HandlerRoute('Login')"
                      color="#061F42"
                      rounded
                      class="white--text font-light text-capitalize"
                    >
                     ¿Ya tienes cuenta?
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </v-card>
            <experience :registerData="registerInfo" v-show="!registerData" />
          </ValidationObserver>
        </v-col>
      </v-row>
    </v-container>
  </v-responsive> -->
</template>

<script>
import moment from "moment";
// import ToolbarCore from "../core/Toolbar";
import CountryAutocomplete from "@/components/Utils/country_autocomplete";
import Experience from "./Experience";
export default {
  components: {
    // "toolbar-core": ToolbarCore,
    "country-autocomplete": CountryAutocomplete,
    experience: Experience,
  },
  data: () => ({
    registerData: true,
    loading: false,
    name: "",
    last_name: "",
    nickname: "",
    country_id: {},
    phone_number: "",
    password: "",
    password_confirmation: "",
    checkbox: null,
    registerInfo: {},
    country_select: {},
    phone_code: "",
    isValid: false,
    dynamicImageUrl: null,
  }),

  computed: {
    hasData() {
      return this.phone_number == "" ? false : true;
    },
  },

  watch: {
    phone_number(code) {
      if (code.length == this.phone_code.length - 1) {
        if (this.phone_code == `+${code}`) {
          this.isValid = true;
          this.$snotify.warning(
            "El codigo del pais ya ha sido seleccionado",
            "Alerta!"
          );
        } else {
          this.isValid = false;
        }
      }
    },
  },

  created() {
    const subdomain = window.location.hostname.split('.')[0];
    const brand = subdomain && subdomain !== 'localhost' ? subdomain : 'app';
    this.dynamicImageUrl = `https://s3.amazonaws.com/assets.pilsala.com/brand_logos/${brand}.png`;
  },

  methods: {
    HandlerRegister() {
      const time = moment().format("HH:mm:ss");
      const request = {
        name: this.name,
        last_name: this.last_name,
        nickname: this.nickname,
        country_id: this.country_id.id,
        phone_number: this.phone_number,
        password: this.password,
        password_confirmation: this.password,
        time_utc: time,
      };
      this.registerInfo = { ...request };
      this.registerData = !this.registerData;
    },

    HandlerGoBack() {
      if (!this.registerData) {
        this.registerData = !this.registerData;
      } else {
        this.$router.go(-1);
      }
    },

    HandlerRoute(name) {
      this.$router.push({ name: name });
    },

    HandlerCountryResponse(val) {
      this.phone_code = val.phone_code;
      this.country_id = val;
    },

    HandlerShowConditions() {
      window.location = "https://pilsa.io/conditions";
    },
  },
};
</script>

<style scoped>
@media (max-width: 1024px) {
  .img-left {
    display: none !important;
  }
  .setter {
    width: 100% !important;
    /* width: 100vw !important; */
    /* background-color: red !important; */
  }
  .div-rigth {
    width: 100% !important;
    padding: 0 1rem !important;
  }
}

.mtoop {
  margin-top: 10rem;
}
a {
  color: #0076ff;
}
.mb-20 {
  margin-bottom: 10rem;
}
.w-50 {
  width: 50%;
}
.bg-red {
  background-color: red;
}
.p-relative {
  color: #949494;
  font: normal normal 300 13px/20px Poppins;
  bottom: 0;
  /* margin-top: 3rem;
  padding-top: 4rem; */
}
.p13 {
  font-size: 13px;
}
.p15 {
  font: normal normal normal 15px/21px Poppins;
  font-size: 15px;
}
.mt--2 {
  margin-top: -10px;
}
.section-rigth {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.p-text {
  margin-top: 3rem;
  font-family: "Poppins", serif;
  font-size: 28px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  text-align: center;
}
.setter {
  margin: 0px;
  padding: 0px;
  display: flex;
  height: 100vh;
  justify-content: space-between;
}
.img-left {
  width: 50vw;
  display: flex;
}
.flex-column {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
.div-rigth {
  position: relative;
  width: 50vw;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: auto;
  flex-wrap: wrap;
  padding: 0 6rem;
  padding-top: 1em;
}
.conditionsAndTermins {
  text-align: left;
  font: normal normal 300 13px/20px Poppins;
}
.d-flex {
  display: flex;
}
.center {
  align-items: center;
  justify-content: center;
}
.d-flex-2 {
  /* background-color: red; */
  display: flex;
  justify-content: space-between;
}
.max-width {
  max-width: 250px;
}
</style>
