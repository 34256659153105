<template>
  <div class="loader-container">
    <div class="loader" :style="{ width: `${percentage}%` }"></div>
  </div>
</template>

<script>
export default {
  props: {
    percentage: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style scoped>
.loader-container {
  width: 100%;
  height: 15px;
  background-color: #f3f3f3;
  border-radius: 50px;
  overflow: hidden;
}

.loader {
  height: 100%;
  border-radius: 1rem;
  background-color: #0076ff;
  transition: width 0.4s ease-in-out;
}
</style>
