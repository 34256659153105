<template>
  <button class="containerPillClick">
    <div class="containerTitleAndButtonsList">
      <div class="containerTitleList">
        <span class="subtitleList--text">CD: {{ data.code }}</span>
        <span class="titleList--text"> {{ data.name || "Titulo no definido" }}</span>
      </div>
      <div class="containerBtnsList">
        <button class="containerBtn--seeRigthNow">
          <v-icon class="mr-2" color="white">mdi-view-compact</v-icon>
          <span @click="HandlerShowContent(data)">{{ data.id }} Ver Detalles</span>
        </button>
      </div>
    </div>
  </button>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    icon: {
      type: String,
      default: "",
    },
    levelCode: {
      type: String,
      default: "",
    },
    favoriteDefault: {
      type: Boolean,
      default: false,
    },
    hiddenFav: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isFavorite: false,
    };
  },
  mounted() {
    this.isFavorite = this.favoriteDefault;
    console.log('levelCode',this.levelCode);
  },
  methods: {
    async HandlerAddFavorite() {
      try {
        if (this.data.whatsapp_accounts.length == 0) {
          return;
        } else {
          this.loading = true;
          const request = { content_id: this.data.id };
          const response = await this.$store.dispatch(
            "profile/LIST_CONTENT_FAVORITE",
            request
          );
          this.isFavorite = true;
          this.$emit("cardList:change", response.data.data);
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async HandlerShowContent(data) {
      this.$router.push({
        name: "detail_module",
        query: { module_id: data.id, level_id: this.levelCode },
      });
    },
    cutShortString(string, longitud = 75) {
      if (string.length > longitud) {
        string = string.substring(0, longitud);
        string += "...";
      }
      return string;
    },
  },
};
</script>

<style scoped>
@media (max-width: 768px) {
  .containerPillClick_img {
    margin-top: 1rem !important;
  }
  .containerPillClick {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
  }

  .containerTitleAndButtonsList {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
  .containerBtnsList {
    display: flex !important;
    /* flex-direction: column; */
    gap: 1rem !important;
  }
  .titleList--text,
  .subtitleList--text {
    max-width: 215px !important;
  }
}
.favoriteClass {
  background-color: #f1416c !important;
}
.imgContainTT {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  width: 145px;
  height: 145px;
  /* border-radius: 12px; */
}
.white-10 {
  background-color: #a4a4a438;
}

.circleIcon {
  width: 35px;
  height: 35px;
  position: relative;
  z-index: 10;
  display: flex;
  align-items: center;
  /* overflow: hidden; */
  border-radius: 100px;
  padding: 5px;
  justify-content: center;
}

.containerIconBtn--seeRigthNow {
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.containerBtn--seeRigthNow {
  background: #0076ff 0% 0% no-repeat padding-box;
  border-radius: 32px;
  /* height: 36px; */
  text-align: center;
  font: normal normal normal 13px/30px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
  padding: 9px 20.5px 8px 36.5px;
  padding: 9px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.containerBtnsList {
  display: flex;
  align-items: center;
  /* margin-left: 2rem; */
}

.subtitleList--text {
  text-align: left;
  font: normal normal 300 12px Poppins;
  letter-spacing: 0px;
  color: #6f7b87;
}
.titleList--text {
    text-align: left;
    font: normal normal bold 15px/23px Poppins;
    letter-spacing: 0px;
    color: #101820;
    padding-bottom: 10px;
}
.containerTitleList {
  display: flex;
  flex-direction: column;
  text-align: left;
  /* margin-left: 2rem; */
}
.containerPillClick {
  width: 100%;
  /* height: 145px; */
  display: flex;
  /* background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box; */
  border: 1px solid var(--unnamed-color-dfdfdf);
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dfdfdf;
  border-radius: 8px;
  opacity: 1;
}
.containerTitleAndButtonsList {
  display: flex;
  padding: 21px 21px 21px 21px;
  flex-direction: column;
  width: 100%;
}

.containerPillClick_img {
  border-radius: 8px 0px 0px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 165px;
  height: 145px;
}
</style>
