<template>
  <div class="loader-container">
    <div class="loader" :style="{ width: `${percentage}%` }"></div>
  </div>
</template>

<script>
export default {
  props: {
    percentage: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style scoped>
@media (max-width: 1024px) {
  .loader-container {
    display: none !important;
  }
}
.loader-container {
  width: 100%;
  height: 20px;
  background-color: #f3f3f3;
  border-radius: 50px;
  overflow: hidden;
}

.loader {
  height: 100%;
  border-radius: 1rem;
  background: transparent
    linear-gradient(136deg, #febd8e 0%, #eb64d3 49%, #7d8dff 100%) 0% 0%
    no-repeat padding-box;
  transition: width 0.4s ease-in-out;
}
</style>
