<template>
  <div>
    <toolbar-core />
    <v-container class="mb-15">
      <v-row justify="center">
        <v-col cols="12" md="8">
          <v-card class="mt-5 mt-md-0">
            <v-card-text>
              <v-row class="mt-10 d-flex">
                <v-col cols="12" md="6">
                  <div class="d-flex justify-center">
                    <v-btn
                      @click="HandlerRouter"
                      rounded
                      dark
                      color="#0DAAC9"
                      class="font-light text-capitalize"
                    >
                      VOLVER AL DASHBOARD
                    </v-btn>
                  </div>
                  <div class="mt-8">
                    <p class="mt-0 mb-1 mr-2 text-center text-title">MÓDULO</p>
                    <v-select
                      v-if="!loading_module"
                      :items="modules"
                      item-value="id"
                      item-text="name"
                      v-model="moduleId"
                      class="mx-15 px-3"
                      outlined
                      disabled
                      dense
                    ></v-select>
                    <div v-else class="px-15">
                      <v-progress-linear
                        indeterminate
                        color="#20B1CD"
                      ></v-progress-linear>
                    </div>
                  </div>
                  <div class="mt-0">
                    <p class="mt-0 mb-1 mr-2 text-center text-title">NIVELES</p>
                    <!-- :disabled="module_id == '' ? true : false" -->
                    <v-select
                      v-if="!loading_module"
                      :items="levels"
                      item-value="id"
                      item-text="name"
                      v-model="levelId"
                      class="mx-15 px-3"
                      outlined
                      disabled
                      dense
                    ></v-select>
                    <div v-else class="px-15">
                      <v-progress-linear
                        indeterminate
                        color="#20B1CD"
                      ></v-progress-linear>
                    </div>
                  </div>
                  <!-- <div class="d-flex justify-center">
                    <v-btn
                      :disabled="loading_module"
                      @click="HandlerSaveModule"
                      color="#20B1CD"
                      rounded
                      dark
                      >Guardar</v-btn
                    >
                  </div> -->
                </v-col>
                <v-col cols="12" md="6" class="align-self-center">
                  <v-card class="card-custom mx-10">
                    <div class="d-flex justify-center">
                      <v-img
                        class="mt-12"
                        contain
                        max-width="45px"
                        src="@/assets/img/fi-rr-e-learning.svg"
                      ></v-img>
                    </div>
                    <p class="text-center mt-2 mb-8">Mi programa</p>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider class="mx-10"></v-divider>
            <v-card-text v-if="!loadingContentData">
              <v-row justify="center">
                <v-col cols="12" md="11">
                  <v-data-iterator
                    :items="content_level"
                    :items-per-page.sync="itemsPerPage"
                    :page.sync="page"
                    :search="search"
                    :sort-by="sortBy.toLowerCase()"
                    :sort-desc="sortDesc"
                    hide-default-footer
                  >
                    <template v-slot:header>
                      <v-row justify="center">
                        <v-col cols="12" md="4" class="ml-md-15">
                          <v-text-field
                            v-model="search"
                            class="mt-5"
                            append-icon="mdi-magnify"
                            outlined
                            rounded
                            dense
                            label="Buscar"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </template>

                    <template v-slot:default="props">
                      <v-row>
                        <v-col
                          v-for="item in props.items"
                          :key="item.name"
                          cols="12"
                          md="11"
                          lg="11"
                        >
                          <card-list-component
                            :title="item.area_name"
                            :description="item.pill_name"
                            :color="
                              item.whatsapp_accounts.length == 0
                                ? 'red'
                                : item.whatsapp_accounts[0].pivot.time == 0
                                ? 'red'
                                : 'blue'
                            "
                            :data="item"
                            @cardList:change="HandlerCardListReponse"
                          />
                          <!-- <p class="ml-1 mt-5 mb-0">
                            Enviado {{ item.created_at | date }}
                          </p> -->
                        </v-col>
                      </v-row>
                    </template>
                  </v-data-iterator>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-text v-else class="mt-5">
              <v-row justify="center">
                <v-progress-circular size="70" indeterminate color="blue">
                </v-progress-circular>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import moment from "moment";
import Notification from "../Utils/notification";
import CardList from "./Components/CardList";
import ToolbarCore from "../core/Toolbar";
export default {
  components: {
    "card-list-component": CardList,
    "toolbar-core": ToolbarCore,
  },

  data() {
    return {
      itemsPerPageArray: [4, 8, 12],
      search: "",
      filter: {},
      sortDesc: false,
      page: 1,
      itemsPerPage: 4,
      sortBy: "name",
      keys: [
        "Name",
        "Calories",
        "Fat",
        "Carbs",
        "Protein",
        "Sodium",
        "Calcium",
        "Iron",
      ],
      items: [
        {
          name: "Frozen Yogurt",
          calories: 159,
          fat: 6.0,
          carbs: 24,
          protein: 4.0,
          sodium: 87,
          calcium: "14%",
          iron: "1%",
        },
        {
          name: "Ice cream sandwich",
          calories: 237,
          fat: 9.0,
          carbs: 37,
          protein: 4.3,
          sodium: 129,
          calcium: "8%",
          iron: "1%",
        },
        {
          name: "Eclair",
          calories: 262,
          fat: 16.0,
          carbs: 23,
          protein: 6.0,
          sodium: 337,
          calcium: "6%",
          iron: "7%",
        },
        {
          name: "Cupcake",
          calories: 305,
          fat: 3.7,
          carbs: 67,
          protein: 4.3,
          sodium: 413,
          calcium: "3%",
          iron: "8%",
        },
        {
          name: "Gingerbread",
          calories: 356,
          fat: 16.0,
          carbs: 49,
          protein: 3.9,
          sodium: 327,
          calcium: "7%",
          iron: "16%",
        },
        {
          name: "Jelly bean",
          calories: 375,
          fat: 0.0,
          carbs: 94,
          protein: 0.0,
          sodium: 50,
          calcium: "0%",
          iron: "0%",
        },
        {
          name: "Lollipop",
          calories: 392,
          fat: 0.2,
          carbs: 98,
          protein: 0,
          sodium: 38,
          calcium: "0%",
          iron: "2%",
        },
        {
          name: "Honeycomb",
          calories: 408,
          fat: 3.2,
          carbs: 87,
          protein: 6.5,
          sodium: 562,
          calcium: "0%",
          iron: "45%",
        },
        {
          name: "Donut",
          calories: 452,
          fat: 25.0,
          carbs: 51,
          protein: 4.9,
          sodium: 326,
          calcium: "2%",
          iron: "22%",
        },
        {
          name: "KitKat",
          calories: 518,
          fat: 26.0,
          carbs: 65,
          protein: 7,
          sodium: 54,
          calcium: "12%",
          iron: "6%",
        },
      ],

      loadingContentData: false,
      loading_module: false,
      modules: [],
      module_id: "",
      levels: [],
      level_id: "",
      content_level: [],
      authUser: {},
    };
  },

  created() {
    this.HandlerGetListModule();
    this.HandlerGetAuthUser();
  },

  filters: {
    date(val) {
      return moment(val).locale("es").calendar();
    },
  },

  watch: {
    module_id(module_id) {
      this.HandlerListLevels(module_id);
      this.HandlerListContent(module_id);
    },
    // level_id(val) {
    //   this.HandlerListContent();
    // },
  },

  computed: {
    numberOfPages() {
      return Math.ceil(this.items.length / this.itemsPerPage);
    },
    filteredKeys() {
      return this.keys.filter((key) => key !== "Name");
    },
    moduleId: {
      get() {
        return this.authUser.module_id;
      },
      set(value) {
        this.module_id = value;
      },
    },
    levelId: {
      get() {
        const level_id = this.levels.find(
          (val) => val == this.authUser.level.toLowerCase()
        );
        return level_id;
      },
      set(value) {
        this.level_id = value;
      },
    },
  },

  methods: {
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1;
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1;
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
    },

    HandlerRouter() {
      this.$router.go(-1);
    },

    async HandlerGetAuthUser() {
      try {
        this.loading_module = true;
        const response = await this.$store.dispatch("auth/ME");
        const module_id = response.data.data.module_id;
        const level = response.data.data.level;
        this.authUser = response.data.data;
        this.HandlerListContent(module_id, level);
        this.HandlerListLevels(module_id);
      } catch (error) {
        console.log(error);
        if (error.response.status == 422) {
          let e = error.response.data.error;
          let showNotification = new Notification(e);
          showNotification.notification;
        }
        this.$snotify.error(error.response.data.message, "Error!");
      } finally {
        this.loading_module = false;
      }
    },

    async HandlerGetListModule() {
      try {
        const response = await this.$store.dispatch("profile/LIST_MODULES");
        this.modules = response.data.data;
      } catch (error) {
        console.log(error);
        if (error.response.status == 422) {
          let e = error.response.data.error;
          let showNotification = new Notification(e);
          showNotification.notification;
        }
        this.$snotify.error(error.response.data.message, "Error!");
      }
    },

    async HandlerListLevels(module_id) {
      try {
        const request = {
          module_id: module_id,
        };
        const response = await this.$store.dispatch(
          "profile/LIST_LEVELS",
          request
        );

        this.levels = response.data.data;
      } catch (error) {
        console.log(error);
        if (error.response.status == 422) {
          let e = error.response.data.error;
          let showNotification = new Notification(e);
          showNotification.notification;
        }
        this.$snotify.error(error.response.data.message, "Error!");
      }
    },

    async HandlerListContent(module_id, level) {
      try {
        this.loadingContentData = true;
        const request = {
          module_id: module_id || this.module_id,
          level: level || this.level_id,
        };

        const response = await this.$store.dispatch(
          "profile/LIST_CONTENT_DATA",
          request
        );

        this.content_level = response.data.data;
      } catch (error) {
        console.log(error);
        if (error.response.status == 422) {
          let e = error.response.data.error;
          let showNotification = new Notification(e);
          showNotification.notification;
        }
        this.$snotify.error(error.response.data.message, "Error!");
      } finally {
        this.loadingContentData = false;
      }
    },

    async HandlerSaveModule() {
      try {
        const request = {
          module_id:
            this.module_id == "" ? this.authUser.module_id : this.module_id,
          level: this.level_id == "" ? this.authUser.level : this.level_id,
        };
        await this.$store.dispatch("profile/UPDATE_MODULE_LEVEL", request);
        this.$snotify.success(
          "Excelente tu informacion ha sido guardada",
          "¡Exitos!"
        );
      } catch (error) {
        if (error.response.status == 422) {
          let e = error.response.data.error;
          let showNotification = new Notification(e);
          showNotification.notification;
        }
        this.$snotify.error(error.response.data.message, "Error!");
      }
    },

    HandlerCardListReponse(favoriteVal) {
      const searchContent = this.content_level.find(
        (val) => val.id == favoriteVal.content_id
      );

      const { favorite, ...newContent } = searchContent;
      favorite;

      const contentFavorite = {
        ...newContent,
        favorite: { ...favoriteVal },
      };

      const indexContent = this.content_level.findIndex(
        (val) => val.id == contentFavorite.id
      );

      if (indexContent > -1) {
        this.content_level.splice(indexContent, 1, contentFavorite);
      }
    },
  },
};
</script>

<style>
</style>