import Swal from "sweetalert2";

class Notification {
    constructor(error) {
        this.error = error;
    }

    get notification() {
        return this.renderNotifications();
    }

    renderNotifications() {
        let list = "";

        for (const prop in this.error) {
            list = list + `<li>${this.error[prop][0]}</li>`;
        }

        Swal.fire({
            icon: "error",
            title: "Oops...",
            html: list,
        });
    }
}

export default Notification;