<template>
  <div>
    <toolbar-core />
    <v-container>
      <v-row justify="center">
        <v-col cols="12" class="pt-0">
          <v-btn
            @click="$router.go(-1)"
            color="#0CAAC8"
            text
            rounded
            class="text-sub-title text-capitalize"
          >
            <v-icon large class="mr-3">mdi-arrow-left-drop-circle</v-icon>
            Volver
          </v-btn>
        </v-col>
        <v-col cols="12" sm="10">
          <p class="text-center text-regular">Hola John</p>
          <p class="text-center text-regular-semi">
            Compra tu suscripción en PILSA y únete a nuestra comunidad de
            aprendizaje
          </p>
        </v-col>
      </v-row>
      <v-row class="my-10">
        <v-col
          v-for="(item, index) in plan"
          :key="index"
          cols="12"
          sm="6"
          md="4"
        >
          <v-card height="470">
            <v-app-bar color="#061F42" height="100">
              <v-spacer></v-spacer>
              <v-toolbar-title class="text-center">
                <div style="color: #0daac9">{{ item.name }}</div>
                <small style="color: #e05492">Membresia</small>
              </v-toolbar-title>
              <v-spacer></v-spacer>
            </v-app-bar>
            <div class="px-10 py-10 text-center">
              <p class="font-weight-bold">Destacado</p>
              <p class="font-weight-bold">$ {{ item.price }}</p>
              <p class="font-weight-bold">Contenido recurrente</p>
              <p class="font-weight-bold">Aprendizaje de por vida</p>
              <p class="font-weight-bold">Temas de actualidad</p>
              <v-divider></v-divider>
              <v-btn
                color="#0CAAC8"
                rounded
                class="white--text font-light mt-10"
                >PAGAR</v-btn
              >
            </div>
          </v-card>
        </v-col>
        <!-- <v-col cols="12" sm="6" md="4">
          <v-card height="470">
            <v-app-bar color="#061F42" height="100">
              <v-spacer></v-spacer>
              <v-toolbar-title class="text-center">
                <div style="color: #0daac9">Prepago Anual</div>
                <small style="color: #e05492">Membresia</small>
              </v-toolbar-title>
              <v-spacer></v-spacer>
            </v-app-bar>
            <div class="px-10 py-10 text-center">
              <p class="font-weight-bold">Destacado</p>
              <p class="font-weight-bold">$22.900</p>
              <p class="font-weight-bold">Contenido recurrente</p>
              <p class="font-weight-bold">Aprendizaje de por vida</p>
              <p class="font-weight-bold">Temas de actualidad</p>
              <v-divider></v-divider>
              <v-btn
                color="#0CAAC8"
                rounded
                class="white--text font-light mt-10"
                >PAGAR</v-btn
              >
            </div>
          </v-card>
        </v-col> -->
        <v-col cols="12" sm="6" md="4">
          <v-card height="470">
            <v-app-bar color="#061F42" height="100">
              <v-spacer></v-spacer>
              <v-toolbar-title class="text-center">
                <div style="color: #0daac9">Pago Corporativo</div>
                <small style="color: #e05492">Membresia</small>
              </v-toolbar-title>
              <v-spacer></v-spacer>
            </v-app-bar>
            <div class="px-10 py-10 text-center">
              <p class="font-weight-bold">Destacado</p>
              <!-- <p class="font-weight-bold">$22.900 COP</p> -->
              <p class="font-weight-bold">Contenido recurrente</p>
              <p class="font-weight-bold">Aprendizaje de por vida</p>
              <p class="font-weight-bold">Temas de actualidad</p>
              <p style="color: #0daac9; font-style: italic">
                ¡Contáctanos y busca las mejores píldoras para tu empresa!
              </p>
              <v-divider></v-divider>
              <v-hover v-slot="{ hover }">
                <v-btn
                  @click="HandlerGoLanding"
                  :color="hover ? '#A7467D' : '#0CAAC8'"
                  rounded
                  class="white--text font-light mt-5"
                  >DESCUBRE MÁS</v-btn
                >
              </v-hover>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Toolbar from "../core/Toolbar";
export default {
  components: {
    "toolbar-core": Toolbar,
  },

  data() {
    return {
      plan: [],
    };
  },

  created() {
    this.HandlerGetPlans();
  },

  methods: {
    HandlerGoLanding() {
      window.location = "https://demp.pilsa.io/";
    },

    async HandlerGetPlans() {
      try {
        const response = await this.$store.dispatch("profile/PLAN_SERVICES");
        console.log(response.data.data);
        this.plan = response.data.data;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style></style>
