<template>
  <div>
    <!-- <toolbar-core /> -->
    <dashboard-new :level-user-percentage="levelUserPercentage.toFixed(1)">
      <template v-slot:header>
        <toolbar-core />
      </template>
      <template v-slot:body>
        <div
          class="ml-10 classContainerBodySlot"
          style="width: calc(100vw - 630px); min-height: calc(100vh - 190px);"
        >
          <h1 class="tittleMyProfile2">
            Favoritos
          </h1>
          <p class="titleMyProfile">
            Panel / Favoritos
          </p>
          <div class="fisrtContainer">
            <span class="fisrtContainer_title">Módulos</span>
            <div class="fisrtContainer_fisrtContainer">
              <v-select
                v-if="!loading_module"
                :items="modules"
                item-value="id"
                item-text="name"
                v-model="moduleId"
                outlined
              ></v-select>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                outlined
                rounded
                label="Buscar"
              ></v-text-field>
            </div>
            <button class="btnSearch">Buscar</button>
          </div>

          <div class="containerTabs">
            <v-tabs v-model="tab">
              <v-tab>
                <span class="capitalize">Básico</span> ({{ basic.length }})
              </v-tab>

              <v-tab>
                <span class="capitalize">Intermedio</span> ({{
                  intermediate.length
                }})
              </v-tab>

              <v-tab>
                <span class="capitalize">Avanzado</span> ({{ advanced.length }})
              </v-tab>
            </v-tabs>
            <div v-for="(item, index) in content_level_data" :key="index">
              <card-list-v2
                :hidden-fav="true"
                :data="item"
                :icon="switchIcon(item)"
              ></card-list-v2>
            </div>
          </div>
        </div>
        <!-- <v-container class="mb-15">
          <v-row justify="center">
            <v-col cols="12" md="8">
              <v-card class="mt-5 mt-md-0">
                <v-card-text>
                  <v-row class="mt-10 d-flex">
                    <v-col cols="12" md="6">
                      <div class="d-flex justify-center">
                        <v-btn
                          @click="HandlerRouter"
                          rounded
                          dark
                          color="#0DAAC9"
                          class="font-light text-capitalize"
                        >
                          VOLVER AL DASHBOARD
                        </v-btn>
                      </div>
                      <div class="mt-8">
                        <p class="mt-0 mb-1 mr-2 text-center text-title">
                          MÓDULO
                        </p>
                        <v-select
                          v-if="!loading_module"
                          :items="modules"
                          item-value="id"
                          item-text="name"
                          v-model="moduleId"
                          class="mx-15 px-3"
                          outlined
                          disabled
                          dense
                        ></v-select>
                        <div v-else class="px-15">
                          <v-progress-linear
                            indeterminate
                            color="#20B1CD"
                          ></v-progress-linear>
                        </div>
                      </div>
                      <div class="mt-0">
                        <p class="mt-0 mb-1 mr-2 text-center text-title">
                          NIVELES
                        </p>
                        <v-select
                          v-if="!loading_module"
                          :items="levels"
                          item-value="id"
                          item-text="name"
                          v-model="levelId"
                          class="mx-15 px-3"
                          outlined
                          disabled
                          dense
                        ></v-select>
                        <div v-else class="px-15">
                          <v-progress-linear
                            indeterminate
                            color="#20B1CD"
                          ></v-progress-linear>
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="12" md="6" class="align-self-center">
                      <v-card class="card-custom mx-10">
                        <div class="d-flex justify-center">
                          <v-img
                            class="mt-12"
                            contain
                            max-width="45px"
                            src="@/assets/img/Icono favorito.svg"
                          ></v-img>
                        </div>
                        <p class="text-center mt-2 mb-8">Mi programa</p>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-divider class="mx-10"></v-divider>
                <v-card-text v-if="!loadingContentData">
                  <v-row justify="center">
                    <v-col cols="12" md="11">
                      <v-data-iterator
                        :items="content_level"
                        :items-per-page.sync="itemsPerPage"
                        :page.sync="page"
                        :search="search"
                        :sort-by="sortBy.toLowerCase()"
                        :sort-desc="sortDesc"
                        hide-default-footer
                      >
                        <template v-slot:header>
                          <v-row justify="center">
                            <v-col cols="12" md="4" class="ml-md-15">
                              <v-text-field
                                v-model="search"
                                class="mt-5"
                                append-icon="mdi-magnify"
                                outlined
                                rounded
                                dense
                                label="Buscar"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </template>

                        <template v-slot:default="props">
                          <v-row>
                            <v-col
                              v-for="item in props.items"
                              :key="item.name"
                              cols="12"
                              md="11"
                              lg="11"
                            >
                              <card-list-component
                                :title="item.area_name"
                                :description="item.pill_name"
                                :color="
                                  item.whatsapp_accounts.length == 0
                                    ? 'red'
                                    : item.whatsapp_accounts[0].pivot.time == 0
                                    ? 'red'
                                    : 'blue'
                                "
                                :data="item"
                                @cardList:change="HandlerCardListReponse"
                              />
                            </v-col>
                          </v-row>
                        </template>
                      </v-data-iterator>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-text v-else class="mt-5">
                  <v-row justify="center">
                    <v-progress-circular size="70" indeterminate color="blue">
                    </v-progress-circular>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container> -->
      </template>
    </dashboard-new>
  </div>
</template>

<script>
import moment from "moment";
import Notification from "../Utils/notification";
import CardList from "./Components/CardList";
import ToolbarCore from "../core/Toolbar";
import Dashboard from "../Dashboard.vue";
import CardListV2 from "../Profile/Components/CardListV2.vue";
export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    "card-list-component": CardList,
    "toolbar-core": ToolbarCore,
    "dashboard-new": Dashboard,
    "card-list-v2": CardListV2,
  },

  data() {
    return {
      itemsPerPageArray: [4, 8, 12],
      search: "",
      filter: {},
      sortDesc: false,
      page: 1,
      itemsPerPage: 4,
      sortBy: "name",
      tab: 0,
      levelUserPercentage: 0,
      content_level_data: [],
      loadingContentData: false,
      loading_module: false,
      advanced: [],
      intermediate: [],
      basic: [],
      modules: [],
      module_id: "",
      levels: [],
      level_id: "",
      content_level: [],
      authUser: {},
    };
  },

  created() {
    this.HandlerGetListModule();
    this.HandlerGetAuthUser();
    this.HandlerGetLevelPercentage();
  },

  filters: {
    date(val) {
      return moment(val)
        .locale("es")
        .calendar();
    },
  },

  watch: {
    module_id(module_id) {
      this.HandlerListLevels(module_id);
      this.HandlerListContent(module_id);
    },

    tab(val) {
      let level;
      switch (val) {
        case 0:
          level = this.basic;
          break;
        case 1:
          level = this.intermediate;
          break;
        case 2:
          level = this.advanced;
          break;
        default:
          level = this.basic;
          break;
      }
      this.content_level_data = level;
      return level;
    },
  },

  computed: {
    moduleId: {
      get() {
        console.log("this.authUser.module_id", this.authUser.module_id);
        return this.authUser.module_id;
      },
      set(value) {
        this.module_id = value;
      },
    },
    levelId: {
      get() {
        const level_id = this.levels.find(
          (val) => val == this.authUser.level.toLowerCase()
        );
        return level_id;
      },
      set(value) {
        this.level_id = value;
      },
    },
  },

  methods: {
    async HandlerGetListDataMyWay() {
      try {
        const response = await this.$store.dispatch(
          "profile/LIST_CONTENT_WATCHED_CONTENT"
        );

        const content = response.data.data;

        if (content.basico != null || content.basico != undefined) {
          this.basic = content.basico;
        }
        if (content.intermedio != null || content.intermedio != undefined) {
          this.intermediate = content.intermedio;
        }
        if (content.avanzado != null || content.avanzado != undefined) {
          this.advanced = content.avanzado;
        }

        switch (this.userLevel) {
          case "basico":
            this.content_level_data = this.basic;
            break;
          case "intermedio":
            this.content_level_data = this.intermediate;
            break;
          case "avanzado":
            this.content_level_data = this.advanced;
            break;
          default:
            this.content_level_data = this.basic;
            break;
        }
      } catch (error) {
        console.log(error);
        if (error.response.status == 422) {
          let e = error.response.data.error;
          let showNotification = new Notification(e);
          showNotification.notification;
        }
        this.$snotify.error(error.response.data.message, "Error!");
      }
    },

    switchIcon(data) {
      const notNull = data.file_type ?? "audio";
      const dictionary = {
        imagen: "mdi-file-image-outline",
        documento: "mdi-file-document-multiple-outline",
        audio: "mdi-play",
        cuestionario: "mdi-file-document-edit-outline",
        test_content: "mdi-book-check-outline",
        canva: "mdi-search-web",
      };
      // console.log("switch", data, dictionary);
      return dictionary[notNull] ?? "";
    },
    HandlerRouter() {
      this.$router.go(-1);
    },

    async HandlerGetLevelPercentage() {
      try {
        const response1 = await this.$store.dispatch("auth/ME");
        const module_id = response1.data.data.module_id;
        const level = response1.data.data.level;

        const request = { module_id, level };
        const response = await this.$store.dispatch(
          "profile/LEVEL_PERCENTAGE",
          request
        );
        this.levelUserPercentage = response.data.data;
      } catch (error) {
        console.log(error);
        if (error.response.status == 422) {
          let e = error.response.data.error;
          let showNotification = new Notification(e);
          showNotification.notification;
        } else {
          this.$snotify.error(error.response.data.message, "Error!");
        }
      }
    },

    async HandlerGetAuthUser() {
      try {
        this.loading_module = true;
        const response = await this.$store.dispatch("auth/ME");
        const module_id = response.data.data.module_id;
        const level = response.data.data.level;
        this.module_id = module_id;
        this.level_id = level;
        this.authUser = response.data.data;
        this.HandlerListContent(module_id, level);
        this.HandlerListLevels(module_id);
        this.HandlerGetListDataMyWay();
      } catch (error) {
        console.log(error);
        if (error.response.status == 422) {
          let e = error.response.data.error;
          let showNotification = new Notification(e);
          showNotification.notification;
        }
        this.$snotify.error(error.response.data.message, "Error!");
      } finally {
        this.loading_module = false;
      }
    },

    async HandlerGetListModule() {
      try {
        const response = await this.$store.dispatch("profile/LIST_MODULES");
        this.modules = response.data.data;
      } catch (error) {
        console.log(error);
        if (error.response.status == 422) {
          let e = error.response.data.error;
          let showNotification = new Notification(e);
          showNotification.notification;
        }
        this.$snotify.error(error.response.data.message, "Error!");
      }
    },

    async HandlerListLevels(module_id) {
      try {
        const request = {
          module_id: module_id,
        };
        const response = await this.$store.dispatch(
          "profile/LIST_LEVELS",
          request
        );

        this.levels = response.data.data;
      } catch (error) {
        console.log(error);
        if (error.response.status == 422) {
          let e = error.response.data.error;
          let showNotification = new Notification(e);
          showNotification.notification;
        }
        this.$snotify.error(error.response.data.message, "Error!");
      }
    },

    async HandlerListContent(module_id, level) {
      try {
        this.loadingContentData = true;
        const request = {
          module_id: module_id || this.module_id,
          level: level || this.level_id,
        };

        const response = await this.$store.dispatch(
          "profile/GET_FAVORITES",
          request
        );

        this.content_level = response.data.data;
      } catch (error) {
        console.log(error);
        if (error.response.status == 422) {
          let e = error.response.data.error;
          let showNotification = new Notification(e);
          showNotification.notification;
        }
        this.$snotify.error(error.response.data.message, "Error!");
      } finally {
        this.loadingContentData = false;
      }
    },

    HandlerCardListReponse() {
      this.HandlerListContent(this.module_id, this.level_id);
    },
  },
};
</script>

<style scoped>
@media (max-width: 1024px) {
  .classContainerBodySlot {
    width: 100% !important;
    padding: 1rem !important;
    margin-left: 0 !important;
  }
}
.capitalize {
  text-transform: capitalize;
}
.containerTabs {
  margin-top: 1rem;
  border-radius: 12px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  background: #ffffff 0% 0% no-repeat padding-box;
}
/* .titleMyProfile{

} */
.btnSearch {
  text-align: center;
  font: normal normal normal 14px/30px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
  background: #0076ff 0% 0% no-repeat padding-box;
  border-radius: 32px;
  padding: 7px 36px;
}
.fisrtContainer_fisrtContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 2rem;
  margin-top: 1rem;
}
.fisrtContainer_title {
  text-align: left;
  font: normal normal bold 18px/27px Poppins;
  letter-spacing: 0px;
  color: #101820;
}
.fisrtContainer {
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 12px;
  background-color: white;
  padding: 1rem;
}
</style>
