<template>
  <div>
    <toolbar-core />
    <v-container>
      <v-row justify="center" class="mt-5 mt-md-0">
        <v-col cols="12" class="pt-0">
          <v-btn
            @click="$router.go(-1)"
            color="#0CAAC8"
            text
            rounded
            class="text-sub-title text-capitalize mt-5 mt-md-0"
          >
            <v-icon large class="mr-3">mdi-arrow-left-drop-circle</v-icon>
            Volver
          </v-btn>
        </v-col>
        <v-col cols="12" md="8">
          <ValidationObserver ref="obs" v-slot="{ passes }">
            <v-card :loading="loading" class="custom-card">
              <v-card-text>
                <h2 class="text-center">Confirma tus datos de la cuenta</h2>
                <v-row justify="center" class="mt-5">
                  <v-col cols="12" md="8">
                    <v-row>
                      <v-col cols="5" sm="4" class="pr-0">
                        <country-autocomplete
                          @dialog:change="HandlerCountryResponse"
                        />
                      </v-col>
                      <v-col cols="7" sm="8" class="pl-0">
                        <ValidationProvider
                          name="telefono"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <v-text-field
                            v-model="phone_number"
                            class="mt-3 ml-auto"
                            solo
                            prepend-inner-icon="mdi-whatsapp"
                            label="Número de Whatsapp"
                            :error-messages="errors"
                          ></v-text-field>
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="12" md="12">
                        <ValidationProvider
                          name="contraseña"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <v-text-field
                            @keyup.enter="passes(HandlerVerify)"
                            type="password"
                            v-model="password"
                            class="mt-md-3"
                            solo
                            prepend-inner-icon="mdi-lock-outline"
                            label="Contraseña"
                            :error-messages="errors"
                          ></v-text-field>
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="12" md="12" class="d-flex justify-center">
                        <v-btn
                          @click="passes(HandlerVerify)"
                          dark
                          color="#1FB1CC"
                          rounded
                          class="mt-n7 mb-5"
                          >Continuar</v-btn
                        >
                      </v-col>
                      <v-col v-show="showMethodToPay" cols="12" md="12">
                        <h3 class="text-center">Continua con tu pago</h3>
                        <div class="mt-5" id="paypal-button-container"></div>
                      </v-col>
                      <v-col
                        cols="12"
                        class="d-flex justify-space-between mt-8"
                      >
                        <small class="my-auto">¿No tienes cuenta?</small>
                        <v-btn
                          color="#061F42"
                          rounded
                          class="white--text font-light"
                          @click="HandlerRoute('Register')"
                          >REGISTRATE</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </ValidationObserver>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
// import axios from "axios";
import Notification from "../Utils/notification";
import ToolbarCore from "../core/Toolbar";
import CountryAutocomplete from "@/components/Utils/country_autocomplete";
export default {
  components: {
    "toolbar-core": ToolbarCore,
    "country-autocomplete": CountryAutocomplete,
  },
  data() {
    return {
      country_id: "",
      phone_number: "",
      password: "",
      showMethodToPay: false,
      response: {},
      loading: false,
      plan: {},
    };
  },
  mounted() {
    const encryptData = this.$route.query.value;
    const dececryptData = this.CryptoJS.AES.decrypt(
      encryptData,
      "My$ncrypt-d4t4-Pl4N-s3cr3t"
    ).toString(this.CryptoJS.enc.Utf8);
    this.plan = {
      ...JSON.parse(dececryptData),
    };
  },
  methods: {
    async HandlerVerify() {
      try {
        this.loading = true;
        const plan = { ...this.plan };
        const request = {
          plan_id: plan.id,
          country_id: this.country_id.id,
          phone_number: this.phone_number,
          password: this.password,
        };
        const response = await this.$store.dispatch(
          "profile/VERIFY_PAY",
          request
        );
        if (response.data.data == true) {
          this.showMethodToPay = response.data.data;
          this.$snotify.success("Listo para realizar el pago", "Exitos!");
          this.HandlerPay();
        } else {
          this.showMethodToPay = response.data.data;
          this.$snotify.warning("Ya posee un plan activo", "Alerta!");
        }
      } catch (error) {
        if (error.response.status == 422) {
          let e = error.response.data.error;
          let showNotification = new Notification(e);
          showNotification.notification;
        } else {
          this.$snotify.error(error.response.data.message, "Error!");
        }
      } finally {
        this.loading = false;
      }
    },

    HandlerCountryResponse(val) {
      this.country_id = val;
    },

    HandlerRoute(name) {
      this.$router.push({ name: name });
    },

    HandlerPay() {
      const plan_id = this.plan.id;
      const amount = this.plan.price;
      const country_id = this.country_id.id;
      const phone_number = this.phone_number;
      const password = this.password;
      let that = this;
      window.paypal
        .Buttons({
          style: {
            layout: "vertical",
            color: "gold",
            shape: "pill",
            label: "paypal",
          },
          createOrder: function (data, actions) {
            // This function sets up the details of the transaction, including the amount and line item details.
            return actions.order.create({
              purchase_units: [
                {
                  amount: {
                    value: amount,
                  },
                },
              ],
            });
          },
          onApprove: function (data, actions) {
            // This function captures the funds from the transaction.
            return actions.order.capture().then(function (details) {
              const request = {
                plan_id: plan_id,
                country_id: country_id,
                phone_number: phone_number,
                password: password,
                payment: details,
              };
              that.HandlerSendPay(request);
              that.$snotify.success(
                "Tu pago se ha realizados con exitos porfavor espera mientras terminaos con el proceso",
                "¡Exitos!"
              );
            });
          },
          onError: (err) => {
            console.error("error from the onError callback", err);
            that.$snotify.error(
              "Ha ocurrido un error comunicate con el administrador del sistema",
              "¡Error!"
            );
          },
        })
        .render("#paypal-button-container");
    },

    async HandlerSendPay(request) {
      try {
        this.loading = true;
        const data = { ...request };
        await this.$store.dispatch("profile/SEND_RESPONSE_PAY", data);
        console.log(request);
        setTimeout(() => {
          this.$router.push({ name: "profile" });
          this.$snotify.success("Un gusto tenerte de vuelta", "¡Bienvenido!");
        }, 1500);
      } catch (error) {
        if (error.response.status == 422) {
          let e = error.response.data.error;
          let showNotification = new Notification(e);
          showNotification.notification;
        } else {
          this.$snotify.error(error.response.data.message, "Error!");
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style></style>