<template>
  <div>
    <dashboard-new>
      <template v-slot:header>
        <toolbar-core />
      </template>
      <template v-slot:body>
        <div
          class="ml-10 classContainerBodySlot"
          style="width: calc(100vw - 630px); height: calc(100vh - 190px); overflow: auto;"
        >
          <v-container>
            <h1 class="tittleMyProfile2">
              {{ data.module.name }}
            </h1>
            <p class="titleMyProfile">
              Módulo
            </p>
            <div v-if="!showNoContent">
              <v-card
                class="stylingContainerDynamic"
                :height="data.file_type == 'audio' ? 500 : 'auto'"
                color="#EEEEEE"
              >
                <div class="headerInsideShowContentDad">
                  <div class="headerInsideShowContent">
                    <span class="headerInsideShowContent_title">
                      {{ data.pill_name }}
                    </span>
                    <span class="headerInsideShowContent_timestamp">
                      Enviado {{ data.created_at | date }}
                    </span>
                  </div>
                  <div class="headerInsideShowContent2">
                    <button @click="HandlerAddFavorite" class="mb-2">
                      <div class="circleIcon white-10 ">
                        <v-icon>mdi-heart-outline</v-icon>
                      </div>
                    </button>
                    <span class="headerInsideShowContent_timestamp">
                      Formato {{ data.file_type || "Analizando" }}
                    </span>
                  </div>
                </div>
                <v-card-text
                  v-if="
                    data.file_type != 'test_content' &&
                      data.file_type != 'cuestionario' &&
                      data.file_type != 'articulo'
                  "
                >
                  <v-row justify="center">
                    <v-col v-if="!loading" cols="12" md="11">
                      <fullscreen ref="fullscreen" @change="fullscreenChange"  style="position: relative;">
                        <v-btn
                          v-if="$fullscreen.support"
                          type="button"
                          :class="['btn', 'btn-default', 'btn-map-fullscreen', data.file_type === 'genially' ? 'fullscreen-align-right' : '']"

                          @click="toggle"
                        >
                          <v-icon>
                            {{
                              fullscreen
                                ? "mdi-fullscreen-exit"
                                : "mdi-arrow-expand"
                            }}</v-icon>
                        </v-btn>

                        <div v-if="data.file_type == 'url'">
                          <v-img
                            v-if="
                              file_type == 'png' ||
                                file_type == 'jpg' ||
                                file_type == 'jpeg'
                            "
                            contain
                            :src="data.file_upload"
                          >
                          </v-img>

                          <iframe
                            v-else
                            :src="data.file_upload"
                            style="height: 100vh; width: 100%"
                          >
                            <p>Your browser does not support iframes.</p>
                          </iframe>
                        </div>
                        <div
                          v-else-if="data.file_type == 'canva'"
                          style="
                        position: relative;
                        width: 100%;
                        height: 0;
                        padding-top: 83.8298%;
                        padding-bottom: 48px;
                        box-shadow: 0 2px 8px 0 rgba(63, 69, 81, 0.16);
                        margin-top: 1.6em;
                        margin-bottom: 0.9em;
                        overflow: hidden;
                        border-radius: 8px;
                        will-change: transform;
                      "
                        >
                          <iframe
                            style="
                          position: absolute;
                          width: 100%;
                          height: 100%;
                          top: 0;
                          left: 0;
                          border: none;
                          padding: 0;
                          margin: 0;
                        "
                            :src="`${data.file_upload}?embed`"
                          >
                          </iframe>
                        </div>
                        <div v-else-if="data.file_type == 'genially'" style=" position: relative;" >
                          <div  v-if="showRotationCt && data.rotation_horizontal" class="rotation-ct showRotationCt">
                            <div class="rotation-item">
                              <div class="phone">
                                </div>
                                <div class="message">
                                  Por favor, gire su dispositivo.
                                </div>
                            </div>
                          </div>
                          <div style="width: 100%;" id="ct-asfas">
                            <div style="position: relative; padding-bottom: 56.25%; padding-top: 0; height: 0;"><iframe frameborder="0" width="1200" height="675" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;" :src="`${data.file_upload}`" type="text/html" allowscriptaccess="always" allowfullscreen="true" scrolling="yes" allownetworking="all"></iframe> </div>
                          </div>
                        </div>
                        <v-img
                          v-else-if="data.file_type == 'imagen'"
                          contain
                          :src="data.file_upload"
                        >
                        </v-img>
                        <vue-plyr v-else-if="data.file_type == 'video'">
                          <video controls>
                            <source :src="data.file_upload" />
                            Your browser does not support the audio element.
                          </video>
                        </vue-plyr>

                        <div
                          v-else-if="data.file_type == 'audio'"
                          class="mt-15 pt-15"
                        >
                          <vue-plyr>
                            <audio style="width: 100%" controls>
                              <source :src="data.file_upload" />
                              Your browser does not support the audio element.
                            </audio>
                          </vue-plyr>
                        </div>
                        <iframe
                          v-else-if="data.file_upload"
                          :src="
                            `https://docs.google.com/gview?url=${data.file_upload}&embedded=true`
                          "
                          style="height: 400px; width: 100%"
                        >
                          <p>Your browser does not support iframes.</p>
                        </iframe>
                      </fullscreen>
                      <!-- <button type="button" @click="toggle">Fullscreen</button> -->
                    </v-col>
                    <v-col
                      v-else
                      cols="12"
                      md="11"
                      class="d-flex justify-center align-center"
                    >
                      <v-progress-circular
                        size="120"
                        indeterminate
                        color="primary"
                      ></v-progress-circular>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-text v-if="data.file_type == 'articulo'">
                  <h3>Por favor click en el siguiente boton</h3>
                  <v-btn
                    color="#0DB2D2"
                    dark
                    class="mt-5"
                    @click="HandlerRedirect(data.file_upload)"
                    >Informacion del articulo</v-btn
                  >
                </v-card-text>
                <v-card-text
                  v-else-if="
                    data.file_type == 'test_content' ||
                      data.file_type == 'cuestionario'
                  "
                >
                  <h3>Complete la siguiente informacion</h3>
                  <h4 v-if="data.file_type == 'test_content'">
                    Solo una respuesta es valida
                  </h4>
                  <h4 v-else>
                    Puede seleccionar mas de una respuesta valida
                  </h4>
                  <div
                    v-for="(item, index) in data.question"
                    :key="index"
                    class="mt-5"
                  >
                    <span class="font-weight-bold">
                      {{ `${index + 1}- ${item.name}` }}
                    </span>
                    <v-radio-group
                      v-model="item.created_at"
                      v-if="data.file_type == 'test_content'"
                    >
                      <v-radio
                        v-for="(value, key) in item.answers"
                        :key="key"
                        :label="`${value.name.toLowerCase()}`"
                        :value="value.id"
                        @change="HandlerGetValue(value, index)"
                      ></v-radio>
                    </v-radio-group>
                    <v-checkbox
                      v-else
                      v-for="(value, key) in item.answers"
                      :key="key"
                      :label="`${value.name.toLowerCase()}`"
                      v-model="value.checkbox"
                      @change="HandlerGetValueCuestionary(value, index)"
                    ></v-checkbox>
                  </div>
                  <v-btn
                    v-if="data.file_type == 'test_content'"
                    color="#20B8D6"
                    dark
                    @click="HandlerSendAnswer"
                  >
                    Enviar
                  </v-btn>
                  <v-btn
                    v-else
                    color="#20B8D6"
                    dark
                    @click="HandlerSendAnswerCuestionary"
                  >
                    Enviar
                  </v-btn>
                </v-card-text>
              </v-card>
            </div>
            <v-row v-else class="mb-15 pb-15" justify="center">
              <v-col cols="12" md="8">
                <v-card height="400" color="#EEEEEE">
                  <v-card flat color="white elevation-0">
                    <v-card-text>
                      <div class="my-5">
                        <p class="headline">
                          Parece que este contenido no esta habilitado
                        </p>
                      </div>
                    </v-card-text>
                  </v-card>
                  <v-card-text>
                    <v-alert
                      class="mt-15 px-15"
                      outlined
                      type="warning"
                      prominent
                      border="left"
                    >
                      No posee este contenido.
                    </v-alert>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <div>
              <div
                class="text-title text-center text-regular classForceSizeTitle"
                v-if="canRating == false"
              >
                ¿Cómo calificas nuestro contenido?
              </div>
              <div class="text-title text-center text-regular" v-else>
                Tu Calificación
              </div>
              <div>
                <div class="d-flex justify-center">
                  <v-rating
                    half-increments
                    :readonly="canRating"
                    v-model="rating"
                    background-color="#061F42"
                    color="#0DB2D2"
                    large
                  ></v-rating>
                </div>
                <div class="d-flex justify-center">
                  <v-btn
                    :loding="loadingRating"
                    @click="HandlerRating"
                    v-if="!canRating && rating > 0"
                    class="mt-3"
                    color="#061F42"
                    dark
                  >
                    Calificar
                  </v-btn>
                </div>
              </div>
            </div>
            <v-row justify="center">
              <v-col col="12" sm="12" md="10" class="my-5">
                <ValidationObserver ref="obs" v-slot="{ passes }">
                  <div class="text-center text-regular">Comentarios</div>
                  <div
                    class="d-flex justify-center"
                    v-if="dataCommentaries.length == 0"
                  >
                    <v-card class="mt-2">
                      <v-card-text>
                        Este modulo aun no tiene comentarios registrados
                      </v-card-text>
                    </v-card>
                  </div>
                  <ValidationProvider
                    name="comentario"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-textarea
                      v-if="showCommentary"
                      v-model="commentary"
                      style="width: 100%"
                      class="mt-3 ml-auto"
                      solo
                      label="Escribe aqui tu comentario"
                      :error-messages="errors"
                      rows="2"
                    ></v-textarea>
                  </ValidationProvider>
                  <div class="d-flex justify-end">
                    <v-btn
                      class="mt-2"
                      :loading="loadingCommentary"
                      color="#0DB2D2"
                      dark
                      @click="showCommentary = !showCommentary"
                    >
                      {{
                        !showCommentary ? "Agrega un Comentario" : "Cancelar"
                      }}
                    </v-btn>
                    <v-btn
                      class="ml-3"
                      v-if="showCommentary"
                      :loading="loadingCommentary"
                      color="#061F42"
                      dark
                      @click="passes(HandlerRegisterCommentary)"
                    >
                      Guardar
                    </v-btn>
                  </div>
                </ValidationObserver>
              </v-col>
              <v-col cols="12" sm="12" md="10">
                <div v-for="(item, index) in dataCommentaries" :key="index">
                  <v-textarea
                    :value="item.body"
                    style="width: 100%"
                    class="mt-3 ml-auto"
                    solo
                    label="Comentario"
                    readonly
                    rows="2"
                    auto-grow
                  ></v-textarea>
                </div>
                <div class="d-flex justify-center">
                  <v-btn
                    v-if="dataCommentaries.length > 2"
                    :loading="loadingCommentary"
                    color="#0DB2D2"
                    dark
                    @click="HandlerMore"
                  >
                    ver Mas
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </template>
    </dashboard-new>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import moment from "moment";
import ToolbarCore from "../core/Toolbar";
import fullscreen from "vue-fullscreen";
import Dashboard from "../Dashboard.vue";
import Vue from "vue";
Vue.use(fullscreen);
export default {
  components: {
    "toolbar-core": ToolbarCore,
    "dashboard-new": Dashboard,
  },
  data() {
    return {
      file: "",
      content_id: "",
      time: "",
      data: {},
      showNoContent: false,
      interval: "",
      file_type: "video",
      loading: false,
      public_key: null,
      can_see: false,
      fullscreen: false,

      // ** SECOND PART DEVELOP **
      loadingCommentary: false,
      showCommentary: false,
      loadingRating: false,
      commentary: "",
      rating: 0,
      rating_general: 0,
      canRating: false,
      canShowRating: false,
      dataCommentaries: [],
      pagination: {},
      radioItem: null,
      answer: [],
      cuestionary: [],
      showRotationCt: true,
    };
  },

  created() {
    // this.HandlerGetCommentary();
    console.log(this.data, "data");
  },

  async mounted() {
    this.content_id = this.$route.query.index;
    this.public_key = this.$route.query.value;
    this.can_see = this.$route.query.v;
    await this.HandlerGetData(this.content_id);
    this.HandlerCounter(); /* ENVIAR LA PETICION DEL TIEMPO CADA 10SEG*/
    this.HandlerGetCommentary();

    setTimeout(() => {
      this.showRotationCt = false;
    }, 3000);
  },

  beforeDestroy() {
    clearInterval(this.interval);
  },

  filters: {
    date(val) {
      return moment(val)
        .locale("es")
        .calendar();
    },
  },

  methods: {
    async HandlerAddFavorite() {
      try {
        if (this.data.whatsapp_accounts.length == 0) {
          return;
        } else {
          this.loading = true;
          const request = { content_id: this.data.id };
          const response = await this.$store.dispatch(
            "profile/LIST_CONTENT_FAVORITE",
            request
          );
          this.$emit("cardList:change", response.data.data);
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    toggle() {
      this.$refs["fullscreen"].toggle(); // recommended
      // this.fullscreen = !this.fullscreen // deprecated
    },
    fullscreenChange(fullscreen) {
      this.fullscreen = fullscreen;
    },
    async HandlerGetValue(item) {
      if (this.answer.length == 0) {
        this.answer.push({
          question_id: item.question_id,
          id: item.id,
        });
      } else {
        const questions_id = this.answer.find(
          (quest) => quest.question_id == item.question_id
        );

        if (questions_id == undefined) {
          this.answer.push({
            question_id: item.question_id,
            id: item.id,
          });
        } else {
          const index = this.answer.findIndex(
            (quest) => quest.question_id == item.question_id
          );
          console.log("editar", index);
          this.answer.splice(index, 1, {
            question_id: item.question_id,
            id: item.id,
          });
        }
      }

      console.log(this.answer);
    },

    async HandlerGetValueCuestionary(item) {
      if (this.answer.length == 0) {
        this.answer.push({
          question_id: item.question_id,
          id: item.id,
        });
      } else {
        const status = this.answer.some((index) => item.id == index.id);

        if (status == true) {
          const position = this.answer.findIndex(
            (index) => item.id == index.id
          );

          if (position !== -1) {
            this.answer.splice(position, 1);
          }
        } else {
          this.answer.push({
            question_id: item.question_id,
            id: item.id,
          });
        }
      }

      console.log(this.answer);
    },

    async HandlerSendAnswer() {
      try {
        let arrays = [];
        for (const iterator of this.answer) {
          arrays.push(iterator.id);
        }

        const request = {
          content_id: this.data.id,
          answers: arrays,
        };
        const response = await this.$store.dispatch(
          "ratings/ANSWER_TEST",
          request
        );

        if (response.data.percentaje < 50) {
          Swal.fire({
            title: `<strong>${response.data.percentaje.toString()}%</strong>`,
            icon: "info",
            html:
              "No has alcanzado el mínimo de aprobación, puedes volverlo a intentar",
            showCloseButton: false,
            showCancelButton: false,
            focusConfirm: false,
            confirmButtonText: "Ok",
          });
        } else if (response.data.percentaje < 50) {
          Swal.fire({
            title: `<strong>${response.data.percentaje.toString()}%</strong>`,
            icon: "info",
            html: "¡Estas cerca!, sigue intentando.",
            showCloseButton: false,
            showCancelButton: false,
            focusConfirm: false,
            confirmButtonText: "Ok",
          });
        } else if (
          response.data.percentaje > 49 &&
          response.data.percentaje < 79
        ) {
          Swal.fire({
            title: `<strong>${response.data.percentaje.toString()}%</strong>`,
            icon: "success",
            html: "¡Estas cerca!, sigue intentando.",
            showCloseButton: false,
            showCancelButton: false,
            focusConfirm: false,
            confirmButtonText: "Ok",
          });
        } else if (response.data.percentaje > 79) {
          Swal.fire({
            title: `<strong>${response.data.percentaje.toString()}%</strong>`,
            icon: "success",
            html:
              "¡Excelente!  Has respondido correctamente. Estás listo para otro módulo de PILSA, tu coach educativo",
            showCloseButton: false,
            showCancelButton: false,
            focusConfirm: false,
            confirmButtonText: "Ok",
          });
        }
      } catch (error) {
        console.log(error);
      }
    },

    async HandlerSendAnswerCuestionary() {
      try {
        let arrays = [];
        for (const iterator of this.answer) {
          arrays.push(iterator.id);
        }

        const request = {
          content_id: this.data.id,
          answers: arrays,
        };

        console.log(request);
        const response = await this.$store.dispatch(
          "ratings/ANSWER_CUESTIONARY",
          request
        );

        console.log(response);
        Swal.fire({
          title: "Cuestionario finalizado",
          icon: "success",
          html: "Sus respuestas fueron registradas con exito ",
          showCloseButton: false,
          showCancelButton: false,
          focusConfirm: false,
          confirmButtonText: "Ok",
        });

        // const array = []
        for (const iterator of this.data.question) {
          iterator.answers.map((index) => (index.checkbox = false));
          // array.push(iterator)
        }
      } catch (error) {
        // console.log(error.response.status);
        // const array = []}}
        for (const iterator of this.data.question) {
          iterator.answers.map((index) => (index.checkbox = false));
          // array.push(iterator)
        }

        if (error.response.status === 502) {
          Swal.fire({
            title: "Oh Oh",
            icon: "warning",
            html: error.response.data.message,
            showCloseButton: false,
            showCancelButton: false,
            focusConfirm: false,
            confirmButtonText: "Ok",
          });
        } else if (error.response.status === 503) {
          Swal.fire({
            title: "Lo siento",
            icon: "warning",
            html: error.response.data.message,
            showCloseButton: false,
            showCancelButton: false,
            focusConfirm: false,
            confirmButtonText: "Ok",
          });
        }
      }
    },

    async HandlerGetData(content_id) {
      try {
        this.loading = true;

        let decryptedPublicKey = this.public_key;
        if (this.public_key == undefined) {
          const public_key = sessionStorage.getItem("Value-rec");
          decryptedPublicKey = this.CryptoJS.AES.decrypt(
            public_key,
            "MyEncrypt-dAtA-sEcrEt"
          ).toString(this.CryptoJS.enc.Utf8);
        }

        const request = {
          index: content_id.replaceAll(" ", "+"),
          public_key: decryptedPublicKey.replaceAll(" ", "+"),
          v: this.can_see == undefined ? null : this.can_see,
        };

        const response = await this.$store.dispatch(
          "profile/DESECRYPT_CONTENT",
          request
        );

        this.rating_general = response.data.score_avg;

        this.canRating = response.data.status_score;
        if (response.data.status_score == true)
          this.rating = response.data.score_user;

        const responseData = response.data.data;

        this.data = responseData;
      } catch (error) {
        console.log(error);
        if (error.response.status == 403) {
          this.showNoContent = true;
        }
      } finally {
        this.loading = false;
      }
    },

    async HandlerGetContent(time) {
      try {
        const request = {
          content_id: this.data.id,
          time: time || 0,
        };
        await this.$store.dispatch("profile/GET_SHOW_CONTENT", request);
      } catch (error) {
        console.log('call HandlerGetContent');
        console.log(error);
      }
    },

    HandlerCounter() {
      let value = 0;
      this.interval = setInterval(() => {
        if (value % 10 == 0) {
          this.time = value;
          this.HandlerGetContent(10);
        }
        value++;
      }, 1000);
    },

    // *** SEGUNDA PARTE DEVELOP ***
    async HandlerRegisterCommentary() {
      try {
        this.loadingCommentary = true;
        const request = {
          body: this.commentary,
          module_id: this.data.module_id,
          content_id: this.data.id,
        };

        const response = await this.$store.dispatch(
          "ratings/REGISTER_COMMENTARY",
          request
        );

        this.$snotify.success(
          `${response.data.message}, en espera de aprobación`,
          "Exitos!"
        );
        this.$refs.obs.reset();
        this.commentary = "";
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingCommentary = false;
      }
    },

    async HandlerGetCommentary(page = 1) {
      try {
        this.loadingCommentary = true;
        const request = {
          page: page,
          content_id: this.data.id,
        };

        const response = await this.$store.dispatch(
          "ratings/GET_COMMENTARY",
          request
        );
        this.dataCommentaries.push(...response.data.data.data);
        this.pagination = {
          current_page: response.data.data.current_page,
          next_page_url: response.data.data.next_page_url,
          last_page: response.data.data.last_page,
        };
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingCommentary = false;
      }
    },

    HandlerMore() {
      if (this.pagination.last_page > this.pagination.current_page) {
        this.HandlerGetCommentary(this.pagination.current_page + 1);
      } else {
        this.$snotify.warning(
          "Ya no hay mas elementos para mostrar",
          "Alerta!"
        );
      }
    },

    HandlerRedirect(url) {
      window.open(url, "_blank");
    },

    async HandlerRating() {
      try {
        this.loadingRating = true;
        const request = {
          score: this.rating,
          module_id: this.data.module_id,
          content_id: this.data.id,
        };
        const response = await this.$store.dispatch(
          "ratings/REGISTER_SCORE",
          request
        );
        this.$snotify.success(response.data.message, "Exitos!");
        this.canRating = true;
        this.rating_general = response.data.data_score;
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingRating = false;
      }
    },
  },
};
</script>

<style scoped>
@media (max-width: 1024px) {
  .classContainerBodySlot {
    width: 100% !important;
    padding: 1rem !important;
    margin-left: 0 !important;
  }
}

@media (max-width: 500px) {
  .headerInsideShowContentDad {
    justify-content: center !important;
  }
}
.classForceSizeTitle {
  margin: 2rem 0;
}
.white-10 {
  background-color: #a4a4a438;
}

.circleIcon {
  width: 35px;
  height: 35px;
  position: relative;
  z-index: 10;
  display: flex;
  align-items: center;
  /* overflow: hidden; */
  border-radius: 100px;
  padding: 5px;
  justify-content: center;
}
.headerInsideShowContentDad {
  display: flex;
  justify-content: space-between;
  margin: 0 1rem;
  /* flex-wrap: wrap-reverse; */
  width: calc(100% - 1rem);
  /* align-items: flex-start; */
  /* background-color: red; */
}
.headerInsideShowContent_timestamp {
  text-align: left;
  font: normal normal 300 14px/21px Poppins;
  letter-spacing: 0px;
  color: #8a98a5;
}
.headerInsideShowContent_title {
  /* max-width: 215px; */
  margin-bottom: 1rem;

  /* width: 30%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; */
  /* margin-bottom: 0.5rem; */
  text-align: left;
  font: normal normal bold 18px/27px Poppins;
  letter-spacing: 0px;
  color: #101820;
}
.headerInsideShowContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
}

.headerInsideShowContent2 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.stylingContainerDynamic {
  background-color: white !important;
  box-shadow: none !important;
  padding: 1rem;
  width: 100%;
}

.rotation-ct {
  z-index: 995;
  position:absolute;
  background: #0000006b;
  height: 100%;
  width: 100%;
}

.rotation-item {
  position: relative; height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.phone {
  height: 50px;
  width: 100px;
  border: 3px solid white;
  border-radius: 10px;
  animation: rotate 1.5s ease-in-out infinite alternate;
  /* display: none; */
}

.message {
  color: white;
  font-size: 1em;
  margin-top: 40px;
  /* display: none; */
}

@keyframes rotate {
  0% {
		transform: rotate(0deg)
	}
	50% {
		transform: rotate(-90deg)
	}
	100% {
		transform: rotate(-90deg)
	}
}

.fullscreen-align-right {
  position: absolute;
  top: 4px;
  z-index: 999;
  right: 5px;
}


@media only screen and (max-device-width: 812px) and (orientation: landscape) {
  .phone, .message {
    display: block;
  }
}

@media only screen and (max-width: 768px) and (orientation: portrait) {
  .showRotationCt {
    display: block;
  }
}

@media only screen and (max-width: 768px) and (orientation: landscape) {
  .showRotationCt {
    display: none;
  }
}


</style>

