<template>
  <v-responsive>
    <v-row justify="center">
      <v-dialog persistent max-width="550" width="600px">
        <v-card>
          <v-card-title class="d-flex justify-center">
            <p style="color: #929292">Código de autenticación</p>
          </v-card-title>
          <v-card-text>
            <v-row justify="center">
              <v-col cols="12" md="6">
                <div>
                  <p class="text-center">
                    Hemos enviado un código al número de teléfono +13102597522.
                  </p>
                  <p class="text-center">Es válido durante 15 minutos.</p>
                </div>
                <v-row justify="center">
                  <v-btn
                    color="#0CAAC8"
                    rounded
                    class="mt-5 white--text font-light"
                  >
                    ACEPTAR
                  </v-btn>
                </v-row>
              </v-col>
            </v-row>
            <v-row justify="center" class="mt-10">
              <v-col cols="12" md="10">
                <p style="color: #929292" class="text-center">
                  Ingresa código de autenticación
                </p>
                <v-text-field solo></v-text-field>
              </v-col>
            </v-row>
            <v-row justify="center" class="mt-0">
              <v-btn
                color="#0CAAC8"
                rounded
                class="mt-0 white--text font-light"
              >
                INICIAR SESIÓN
              </v-btn>
            </v-row>
            <v-row justify="center">
              <p style="color: #0daac9" class="mt-5">
                ¿Olvidaste tu contraseña?
              </p>
            </v-row>
            <div class="d-flex justify-center mt-5">
              <p style="color: #061F42" class="mt-7">¿No tienes cuenta?</p>
              <v-btn
                color="#061F42"
                rounded
                class="mt-5 ml-3 white--text font-light"
              >
                ACEPTAR
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </v-responsive>
</template>

<script>
export default {
  data() {
    return {
      dialog: true,
    };
  },
};
</script>

<style>
.custom-color {
  color: #929292;
}
</style>