<template>
  <div>
    <dashboard-new :level-user-percentage="levelUserPercentage.toFixed(1)">
      <template v-slot:header>
        <toolbar-core />
      </template>
      <template v-slot:body>
        <div
          class="ml-10 classContainerBodySlot"
          style="width: calc(100vw - 630px); height: calc(100vh - 190px); overflow: auto;"
        >
          <h1 class="tittleMyProfile2">
            Mi Recorrido
          </h1>
          <p class="titleMyProfile">
            Panel / Mi Recorrido
          </p>
          <section class="sectionDad">
            <div class="containerFilterModules">
              <p class="containerFilterModules_title-Modules">Módulos</p>
              <v-select
                v-if="!loading_module"
                readonly
                :items="modules"
                item-value="id"
                item-text="name"
                v-model="module_id"
                class="w-100"
                outlined
              ></v-select>
              <div class="containerBtnsFilter">
                <button class="btnFilter"><span>Filtrar</span></button>
                <button
                  class="btnPrintCerticate"
                  @click="HandlerDowload"
                  :disabled="canDowload ? false : true"
                >
                  <span>Imprimir Certificado</span>
                </button>
              </div>
            </div>
            <div class="containerPathGone">
              <div class="d-flex containerIconTitlePathGone">
                <v-icon size="40">mdi-airplane-takeoff</v-icon>
                <div class="containerIconTitlePathGone_titles">
                  <span class="containerIconTitlePathGone_titles--yourWay"
                    >Recorrido</span
                  >
                  <span class="containerIconTitlePathGone_titles--yourActivity"
                    >Tu actividad de píldoras visualizadas</span
                  >
                </div>
                <!-- <v-avatar size="100">
                  <v-icon>mdi-airplane-takeoff</v-icon>
                </v-avatar> -->
              </div>
              <div class="containerPillsCounts">
                <div class="containerPillsCounts0">
                  <div class="containerPillCount">
                    <h1 class="pillCountStyle colorBlue">{{ viewedContentCounter }}</h1>
                    <div class="d-flex flex-column">
                      <span class="pillTitleStyle">Píldoras</span>
                      <span class="pillTitleStyle">Completadas</span>
                    </div>
                  </div>
                  <div class="containerPillCount">
                    <h1 class="pillCountStyle ">{{ unviewedContentCounter }}</h1>
                    <div class="d-flex flex-column">
                      <span class="pillTitleStyle">Píldoras</span>
                      <span class="pillTitleStyle">Pendientes</span>
                    </div>
                  </div>
                </div>
                <div class="containerPathGone--loader">
                  <div class="containerPathGone--loader--titles">
                    <span class=" containerPathGone--loader--titles--yourPath"
                      >Tu recorrido</span
                    >
                    <span
                      class=" containerPathGone--loader--titles--yourPath colorBlue fontBold"
                      >{{ levelUserPercentage.toFixed(1) }}%</span
                    >
                  </div>
                  <loader-unicolor
                    :percentage="levelUserPercentage.toFixed(1)"
                  />
                </div>
              </div>
            </div>
          </section>
          <div class="containerTabs">
            <v-tabs v-model="tab">
              <!-- <v-tabs-slider></v-tabs-slider> -->

              <v-tab>
                <span class="capitalize">Básico</span> ({{ basic.length }})
              </v-tab>

              <v-tab>
                <span class="capitalize">Intermedio</span> ({{
                  intermediate.length
                }})
              </v-tab>

              <v-tab>
                <span class="capitalize">Avanzado</span> ({{ advanced.length }})
              </v-tab>
            </v-tabs>
            <div v-for="(item, index) in content_level_data" :key="index">
              <card-list-v2
                :favorite-default="item.favorite != null"
                :data="item"
                :icon="switchIcon(item)"
              ></card-list-v2>
            </div>
          </div>
        </div>
        <!-- <v-container class="mb-15">
          <v-row justify="center">
            <v-col cols="12" md="8">
              <v-card class="mt-5 mt-md-0">
                <v-card-text>
                  <v-row class="mt-10 mb-5 d-flex">
                    <v-col cols="12" md="6">
                      <div class="d-flex justify-center">
                        <v-btn
                          @click="HandlerRouter"
                          rounded
                          dark
                          color="#0DAAC9"
                          class="font-light"
                        >
                          VOLVER AL DASHBOARD
                        </v-btn>
                      </div>
                      <div class="mt-8">
                        <p class="mt-0 mb-1 mr-2 text-center text-title">
                          MÓDULO
                        </p>
                        <v-select
                          v-if="!loading_module"
                          readonly
                          :items="modules"
                          item-value="id"
                          item-text="name"
                          v-model="module_id"
                          class="mx-15 px-3"
                          outlined
                          dense
                        ></v-select>
                        <div v-else class="px-15">
                          <v-progress-linear
                            indeterminate
                            color="#20B1CD"
                          ></v-progress-linear>
                        </div>
                      </div>
                      <div class="mt-5 d-flex justify-center">
                        <v-btn
                          :disabled="canDowload ? false : true"
                          :loading="loadingDowload"
                          @click="HandlerDowload"
                          rounded
                          color="#0DAAC9"
                          class="font-light white--text"
                        >
                          Imprimir Certificado
                        </v-btn>
                      </div>
                    </v-col>
                    <v-col cols="12" md="6" class="align-self-center">
                      <v-card class="card-custom mx-10">
                        <v-card-text>
                          <div class="d-flex justify-center">
                            <v-img
                              class="mt-12"
                              contain
                              max-width="45px"
                              src="@/assets/img/fi-rr-paper-plane.svg"
                            ></v-img>
                          </div>
                          <p class="text-center mt-2">Mi recorrido</p>
                          <v-progress-linear
                            color="#20b1cd"
                            height="20"
                            :value="levelUserPercentage"
                          >
                            <template v-slot:default="{ value }">
                              <strong>{{ Math.ceil(value) }}%</strong>
                            </template>
                          </v-progress-linear>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-text>
                  <v-row justify="center">
                    <v-tabs
                      v-model="tab"
                      background-color="white"
                      centered
                      light
                      icons-and-text
                    >
                      <v-tabs-slider></v-tabs-slider>

                      <v-tab> Básico </v-tab>

                      <v-tab> Intermedio </v-tab>

                      <v-tab> Avanzado </v-tab>
                    </v-tabs>
                  </v-row>
                </v-card-text>
                <v-card-text v-if="!loadingContent">
                  <v-row justify="center" class="mt-5">
                    <v-col
                      cols="12"
                      md="11"
                      v-for="(item, index) in content_level_data"
                      :key="index"
                    >
                      <card-list-component
                        :title="item.area_name"
                        :description="item.pill_name"
                        :data="item"
                        :color="
                          item.whatsapp_accounts[0].pivot.time == 0
                            ? 'red'
                            : 'blue'
                        "
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-text v-else-if="loadingContent">
                  <v-row justify="center">
                    <v-progress-circular size="70" indeterminate color="blue">
                    </v-progress-circular>
                  </v-row>
                </v-card-text>
                <v-card-text v-if="content_level_data.length == 0">
                  <v-col cols="12" md="12">
                    <v-alert
                      class="mx-md-15 mt-md-n8"
                      outlined
                      type="warning"
                      prominent
                      border="left"
                    >
                      No hay información para mostrar
                    </v-alert>
                  </v-col>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container> -->
      </template>
    </dashboard-new>
  </div>
</template>

<script>
import moment from "moment";
import Notification from "../Utils/notification";
import CardList from "./Components/CardList";
import ToolbarCore from "../core/Toolbar";
import Dashboard from "../Dashboard.vue";
import LoaderUnicolor from "../Dashboard/LoaderUniColor.vue";
import CardListV2 from "../Profile/Components/CardListV2.vue";
export default {
  components: {
    "toolbar-core": ToolbarCore,
    // eslint-disable-next-line vue/no-unused-components
    "card-list-component": CardList,
    "dashboard-new": Dashboard,
    "loader-unicolor": LoaderUnicolor,
    "card-list-v2": CardListV2,
  },
  data() {
    return {
      skill: 20,
      loading_module: false,
      loadingContent: false,
      tab: 0,
      modules: [],
      module_id: "",
      authUser: {},
      content_level: {},
      content_level_data: [],
      levelUserPercentage: 0,
      basic: [],
      intermediate: [],
      advanced: [],
      userLevel: "",
      viewedContentCounter: 0,
      unviewedContentCounter: 0,

      // CERTIFICATE
      canDowload: false,
      loadingDowload: false,
    };
  },
  created() {
    this.HandlerGetAuthUser();
    this.HandlerVerifyDowload();
  },

  filters: {
    date(val) {
      return moment(val)
        .locale("es")
        .calendar();
    },
  },

  watch: {
    tab(val) {
      let level;
      switch (val) {
        case 0:
          level = this.basic;
          break;
        case 1:
          level = this.intermediate;
          break;
        case 2:
          level = this.advanced;
          break;
        default:
          level = this.basic;
          break;
      }
      this.content_level_data = level;
      return level;
    },
  },

  methods: {
    switchIcon(data) {
      const dictionary = {
        imagen: "mdi-file-image-outline",
        documento: "mdi-file-document-multiple-outline",
        audio: "mdi-play",
        cuestionario: "mdi-file-document-edit-outline",
        test_content: "mdi-book-check-outline",
        canva: "mdi-search-web",
      };
      // console.log("switch", data, dictionary);
      return dictionary[data.file_type] ?? "";
    },

    HandlerRouter() {
      this.$router.go(-1);
    },

    async HandlerVerifyDowload() {
      try {
        const response = await this.$store.dispatch("ratings/VERIFY_DOWLOAD");
        this.canDowload = response.data.status;
      } catch (error) {
        console.log(error);
      }
    },

    async HandlerDowload() {
      try {
        this.loadingDowload = true;
        const response = await this.$store.dispatch(
          "ratings/DOWLOAD_CERTIFICATE"
        );
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `certificado.pdf`);
        document.body.appendChild(link);
        link.click();
        console.log(response);
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingDowload = false;
      }
    },

    async HandlerGetAuthUser() {
      try {
        this.loading_module = true;
        const response = await this.$store.dispatch("auth/ME");
        const module_id = response.data.data.module_id;
        this.userLevel = response.data.data.level;

        switch (this.userLevel) {
          case "basico":
            this.tab = 0;
            break;
          case "intermedio":
            this.tab = 1;
            break;
          case "avanzado":
            this.tab = 2;
            break;
          default:
            this.tab = 0;
            break;
        }

        this.HandlerGetLevelPercentage(module_id, this.userLevel);
        this.HandlerGetListDataMyWay();
        this.authUser = response.data.data;
        this.modules = [response.data.data.modules];
        this.module_id = module_id;
        this.viewedContentCounter = response.data.data.count_content_viewed_whatsapp_account;
        this.unviewedContentCounter = response.data.data.count_content_not_viewed_whatsapp_account
      } catch (error) {
        console.log(error);
        if (error.response.status == 422) {
          let e = error.response.data.error;
          let showNotification = new Notification(e);
          showNotification.notification;
        }
        this.$snotify.error(error.response.data.message, "Error!");
      } finally {
        this.loading_module = false;
      }
    },

    async HandlerGetListDataMyWay() {
      try {
        const response = await this.$store.dispatch(
          "profile/LIST_CONTENT_WATCHED_CONTENT"
        );

        const content = response.data.data;

        if (content.basico != null || content.basico != undefined) {
          this.basic = content.basico;
        }
        if (content.intermedio != null || content.intermedio != undefined) {
          this.intermediate = content.intermedio;
        }
        if (content.avanzado != null || content.avanzado != undefined) {
          this.advanced = content.avanzado;
        }

        switch (this.userLevel) {
          case "basico":
            this.content_level_data = this.basic;
            break;
          case "intermedio":
            this.content_level_data = this.intermediate;
            break;
          case "avanzado":
            this.content_level_data = this.advanced;
            break;
          default:
            this.content_level_data = this.basic;
            break;
        }
      } catch (error) {
        console.log(error);
        if (error.response.status == 422) {
          let e = error.response.data.error;
          let showNotification = new Notification(e);
          showNotification.notification;
        }
        this.$snotify.error(error.response.data.message, "Error!");
      }
    },

    async HandlerGetLevelPercentage(module, level) {
      try {
        const request = { module_id: module, level: level };
        const response = await this.$store.dispatch(
          "profile/LEVEL_PERCENTAGE",
          request
        );
        this.levelUserPercentage = response.data.data;
      } catch (error) {
        console.log(error);
        if (error.response.status == 422) {
          let e = error.response.data.error;
          let showNotification = new Notification(e);
          showNotification.notification;
        }
        this.$snotify.error(error.response.data.message, "Error!");
      }
    },
  },
};
</script>

<style scoped>
@media (max-width: 1440px) {
  .sectionDad {
    flex-direction: column !important;
  }
  .containerFilterModules,
  .containerPathGone {
    width: 100% !important;
  }
  .containerPillsCounts {
    margin-bottom: 2rem !important;
  }
}

@media (max-width: 1024px) {
  .classContainerBodySlot {
    width: 100% !important;
    padding: 1rem !important;
    margin-left: 0 !important;
  }
  .containerPillsCounts {
    flex-direction: column !important;
    align-items: center !important;
  }
  .containerPathGone--loader {
    margin-bottom: 1rem !important;
  }
  .sectionDad {
    flex-direction: column !important;
  }
  .containerFilterModules,
  .containerPathGone {
    width: 100% !important;
  }
  .containerPillsCounts0 {
    width: 100% !important;
    justify-content: space-around !important;
  }
}
.capitalize {
  text-transform: capitalize;
}
.containerTabsTabs {
  background-color: red;
}
.containerTabs {
  margin-top: 1rem;
  border-radius: 12px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  background: #ffffff 0% 0% no-repeat padding-box;
}
.containerBtnsList {
  display: flex;
  align-items: center;
  margin-left: 2rem;
}

.titleList--text {
  text-align: left;
  font: normal normal bold 15px/23px Poppins;
  letter-spacing: 0px;
  color: #101820;
}

.containerPillsCounts0 {
  display: flex;
  gap: 2rem;
}
.fontBold {
  font-weight: bold !important;
}
.containerPathGone--loader--titles--yourPath {
  text-align: right;
  font: normal normal normal 12px/18px Poppins;
  letter-spacing: 0px;
  color: #101820;
}
.containerPathGone--loader--titles {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.2rem;
}
.containerPathGone--loader {
  width: 170px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 8px;
}
.colorBlue {
  color: #0076ff;
}
.pillCountStyle {
  text-align: left;
  font: normal normal 600 32px/48px Poppins;
  letter-spacing: 0px;
}
.pillTitleStyle {
  text-align: left;
  font: normal normal 300 12px/18px Poppins;
  letter-spacing: 0px;
  color: #6f7b87;
  opacity: 1;
}
/* .containerPillCount {
} */
.containerPillsCounts {
  display: flex;
  justify-content: space-between;
  margin: 0 31px;
  gap: 1rem;
}
.containerIconTitlePathGone_titles--yourWay {
  text-align: left;
  font: normal normal bold 18px/27px Poppins;
  letter-spacing: 0px;
  color: #101820;
}
.containerIconTitlePathGone_titles--yourActivity {
  text-align: left;
  font: normal normal 300 12px/22px Poppins;
  letter-spacing: 0px;
  color: #3e4a56;
  opacity: 1;
}
.containerIconTitlePathGone_titles {
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
  text-align: left;
}
.containerIconTitlePathGone {
  display: flex;
  padding: 22px 31px;
}
.sectionDad {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
.containerPathGone {
  min-width: 300px;
  /* width: clamp(300px, 50%, 600px); */
  width: calc(50% - 0.5rem);
  /* width: 474px;   */
  /* height: 210px; */
  /* background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box; */
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 12px;
  opacity: 1;
}
.w-100 {
  width: 100%;
}
.btnPrintCerticate {
  background: #f6f6f6 0% 0% no-repeat padding-box;
  border-radius: 32px;
  height: 42px;
  text-align: center;
  font: normal normal normal 14px/30px Poppins;
  letter-spacing: 0px;
  color: #101820;
  padding: 11px 19.5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btnFilter {
  height: 42px;
  padding: 11px 39px;
  color: white;
  background: #0076ff 0% 0% no-repeat padding-box;
  border-radius: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.containerBtnsFilter {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: -10px;
  flex-wrap: wrap;
}
.containerFilterModules_title-Modules {
  text-align: left;
  font: normal normal bold 18px/27px Poppins;
  margin-bottom: 20px;
  letter-spacing: 0px;
  color: #101820;
  opacity: 1;
}
.containerFilterModules {
  padding: 20px 30px 27px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 12px;
  opacity: 1;
  /* height: 210px; */
  width: calc(50% - 0.5rem);
  /* width: clamp(300px, 50%, 450px); */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
