<template>
  <v-card style="border-radius: 20px" :color="color" class="mb-2">
    <v-row class="d-flex">
      <v-col cols="12" :md="routeName != 'my_way' ? 10 : 12">
        <v-card
          @click="HandlerShowContent(data)"
          style="border-radius: 20px"
          class="mx-1 my-n2 d-flex mb-0"
          color="#EEEEEE"
        >
          <v-row class="mb-0">
            <v-col cols="12" md="4" class="align-self-center">
              <p
                class="text-center mt-md-1 ml-1 pt-4"
                :style="`color: ${color}`"
              >
                {{ title }}
              </p>
            </v-col>
            <v-col cols="12" md="8">
              <p class="text-center text-md-start mt-md-1 pt-4">
                {{ description }}
              </p>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col
        v-if="routeName != 'my_way'"
        cols="12"
        md="2"
        class="align-self-center mb-0"
      >
        <div class="d-flex justify-center justify-md-start">
          <v-btn v-if="loading == false" icon @click="HandlerAddFavorite">
            <v-icon class="ml-0" color="white">
              {{ isFavorite }}
            </v-icon>
          </v-btn>
          <v-progress-circular
            v-else
            size="20"
            indeterminate
            :color="color == 'blue' ? 'red' : 'blue'"
          >
          </v-progress-circular>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
      default: () => {},
    },
    color: {
      type: String,
      required: false,
      default: "red",
    },
    title: {
      type: String,
      required: true,
      default: "Lorem Inmpsum",
    },
    description: {
      type: String,
      required: true,
      default: "Lorem Inmpsum..................",
    },
  },

  data() {
    return {
      loading: false,
    };
  },

  computed: {
    isFavorite() {
      let favorite;
      if (this.data.favorite == null) {
        favorite = "mdi-heart-outline";
      } else {
        if (this.data.favorite.status == 0) {
          favorite = "mdi-heart-outline";
        } else {
          favorite = "mdi-heart";
        }
      }
      return favorite;
    },
    routeName() {
      return this.$route.name;
    },
  },

  methods: {
    async HandlerAddFavorite() {
      try {
        if (this.data.whatsapp_accounts.length == 0) {
          return;
        } else {
          this.loading = true;
          const request = { content_id: this.data.id };
          const response = await this.$store.dispatch(
            "profile/LIST_CONTENT_FAVORITE",
            request
          );
          this.$emit("cardList:change", response.data.data);
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async HandlerShowContent(content) {
      console.log("content", content);
      if (content.whatsapp_accounts.length == 0) {
        return () => {};
      } else {
        const request = { content_id: content.id };

        const response = await this.$store.dispatch(
          "profile/ENCRYPT_CONTENT",
          request
        );

        const data_encrypt = response.data.public_key;

        const encryptData = this.CryptoJS.AES.encrypt(
          data_encrypt,
          "MyEncrypt-dAtA-sEcrEt"
        ).toString();

        sessionStorage.setItem("Value-rec", encryptData);
        this.$router.push({
          name: "show_content",
          query: { index: response.data.data_encrypt },
        });
      }
    },
  },
};
</script>

<style></style>
