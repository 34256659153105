<template>
  <v-dialog transition="slide-x-reverse-transition" v-model="active">
    <v-list-item-group class="containerListnot" shaped>
      <div
        class="d-flex justify-space-between px-5 py-5"
        style="background-color: white;"
      >
        <h3 style="font-weight: bold;">Notificaciones</h3>
        <v-btn @click="close" class="mt-1 mr-4 mr-md-0" x-small icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <div class="dialogNotification-bg">
        <h4 class="my-5">Nuevas</h4>
        <div
          @click="HandlerShowContent(item)"
          v-show="new_notifications.length > 0"
          v-for="(item, index) in new_notifications"
          :key="index + 'newNotifications'"
        >
          <!-- <div class="d-flex justify-start mb-0 ">
                    <v-avatar color="#EEEEEE" size="40" class="mr-1">
                      <v-icon color="blue">mdi-pill</v-icon>
                    </v-avatar>
                    <span class="mt-0 mt-md-2" v-html="item.text_body"> </span>
                  </div> -->
          <div class="alertNotification mt-3">
            <div class="d-flex alignCenter">
              <div class="mr-2">
                <div class="circleAlertNotify-filled"></div>
              </div>
              <span>{{ item.text_body }}</span>
            </div>

            <p class="stylesTimestampAlert">
              {{ formatTimestamp(item.created_at) }}
            </p>
          </div>
          <!-- <small
                style="color: #0daac9"
                class="d-flex justify-end mr-5 mr-md-0"
                >{{ item.created_at | date }}</small
              > -->
        </div>
        <div v-show="new_notifications.length == 0">
          <div class="alertNotification">
            <div class="d-flex alignCenter">
              <div>
                <div class="circleAlertNotify-filled"></div>
              </div>
              <span>No tienes notificaciones para mostrar</span>
            </div>
          </div>
        </div>
        <v-divider></v-divider>
        <h4 class="my-5">Anteriores</h4>
        <div
          v-show="old_notifications.length > 0"
          v-for="(item, index) in old_notifications"
          :key="index + 'oldNotifications'"
        >
          <!-- <div class="d-flex justify-start mb-0">
                    <v-avatar color="#EEEEEE" size="40" class="mr-1">
                      <v-icon color="#DBDBDB">mdi-pill</v-icon>
                    </v-avatar>
                    <span class="mt-md-2" style="color: #dbdbdb">{{
                      item.text_body
                    }}</span>
                  </div> -->

          <div class="alertNotification mt-3">
            <div class="d-flex alignCenter">
              <div class="mr-2">
                <div class="circleAlertNotify-filled"></div>
              </div>
              <span>{{ item.text_body }}</span>
            </div>

            <p class="stylesTimestampAlert">
              {{ formatTimestamp(item.created_at) }}
            </p>
          </div>
          <!-- <small
                    style="color: #dbdbdb"
                    class="d-flex justify-end mr-5 mr-md-0"
                  >
                    {{ item.created_at | date }}
                  </small> -->
        </div>
        <div v-show="old_notifications.length == 0">
          <div class="alertNotification">
            <div class="d-flex alignCenter">
              <div>
                <div class="circleAlertNotify-vacum"></div>
              </div>
              <span>No tienes notificaciones para mostrar</span>
            </div>
          </div>
        </div>
      </div>
    </v-list-item-group>
  </v-dialog>
</template>

<script>
import moment from "moment";
// import vuescroll from "vuescroll";
export default {
  components: {
    // vuescroll,
  },

  props: {
    active: {
      type: Boolean,
      required: true,
      default: true,
    },
  },

  data() {
    return {
      loading: false,
    };
  },

  filters: {
    date(val) {
      return moment(val, "YYYYMMDD")
        .locale("es")
        .fromNow();
    },
  },

  computed: {
    new_notifications() {
      // const jsonTest = [
      //   {
      //     id: 5856,
      //     whatsapp_account_id: 371,
      //     diary_id: 581,
      //     content_id: 72,
      //     module_id: 5,
      //     url:
      //       "https://app.pilsa.io/show_content?index=qP9Rv0OhgUw=&value=7YOhIeOGnVE=",
      //     level: "basico",
      //     file_upload:
      //       "https://s3.amazonaws.com/assets.pilsala.com/notificaciones/1680545722.jpg",
      //     file_upload_filename: "1680545722.jpg",
      //     text_body:
      //       "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      //     status: 1,
      //     created_at: "2023-05-29T16:00:07.000000Z",
      //     updated_at: "2023-05-29T16:03:05.000000Z",
      //     type: "content",
      //     phone_number: "584146975885",
      //     state: "SUCCESS",
      //     whatsapp_template_name: "course_reminder_notification",
      //     whatsapp_template_params:
      //       '["Emanuel","Big Data","https://pilsa.io/show_content?index=qP9Rv0OhgUw=&value=7YOhIeOGnVE="]',
      //     whatsapp_account_time_utc: "16:00:00",
      //     send_retry: 1,
      //     whatsapp_message_status: "read",
      //     whatsapp_message_id:
      //       "wamid.HBgMNTg0MTQ2OTc1ODg1FQIAERgSQzU3OTcxMDhGMDZCNjNBRDg4AA==",
      //     company_id: null,
      //     modules: {
      //       id: 5,
      //       name: "Big Data",
      //       created_at: "2021-04-07T22:53:55.000000Z",
      //       updated_at: "2023-07-27T04:13:51.000000Z",
      //       deleted_at: null,
      //       company_id: null,
      //       code: "PL-C0-XR7X",
      //       user_id: null,
      //       parent_id: null,
      //       is_master: 1,
      //     },
      //   },
      //   {
      //     id: 5861,
      //     whatsapp_account_id: 371,
      //     diary_id: 582,
      //     content_id: 73,
      //     module_id: 5,
      //     url:
      //       "https://app.pilsa.io/show_content?index=EvQPZcCFoiQ=&value=a2Y2e1VSo0Y=",
      //     level: "basico",
      //     file_upload:
      //       "https://s3.amazonaws.com/assets.pilsala.com/notificaciones/1680545792.jpg",
      //     file_upload_filename: "1680545792.jpg",
      //     text_body:
      //       "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      //     status: 1,
      //     created_at: "2023-05-30T16:00:07.000000Z",
      //     updated_at: "2023-05-31T16:28:29.000000Z",
      //     type: "content",
      //     phone_number: "584146975885",
      //     state: "SUCCESS",
      //     whatsapp_template_name: "course_reminder_notification",
      //     whatsapp_template_params:
      //       '["Emanuel","Big Data","https://pilsa.io/show_content?index=EvQPZcCFoiQ=&value=a2Y2e1VSo0Y="]',
      //     whatsapp_account_time_utc: "16:00:00",
      //     send_retry: 1,
      //     whatsapp_message_status: "read",
      //     whatsapp_message_id:
      //       "wamid.HBgMNTg0MTQ2OTc1ODg1FQIAERgSMDAzNDVGOTE2Q0Q4MTY5QTVEAA==",
      //     company_id: null,
      //     modules: {
      //       id: 5,
      //       name: "Big Data",
      //       created_at: "2021-04-07T22:53:55.000000Z",
      //       updated_at: "2023-07-27T04:13:51.000000Z",
      //       deleted_at: null,
      //       company_id: null,
      //       code: "PL-C0-XR7X",
      //       user_id: null,
      //       parent_id: null,
      //       is_master: 1,
      //     },
      //   },
      // ];
      // console.log(
      //   "new_notifications",
      //   this.$store.getters["profile/GET_NEW_NOTIFICATION"]
      // );
      return this.$store.getters["profile/GET_NEW_NOTIFICATION"];
      // return jsonTest;
    },
    old_notifications() {
      return this.$store.getters["profile/GET_OLD_NOTIFICATION"];
    },
  },

  methods: {
    formatTimestamp(timestamp) {
      // Crear un objeto Date a partir del timestamp
      let date = new Date(timestamp);

      // Opciones para el formato de la fecha
      let options = {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      };

      // Convertir la fecha a una cadena con el formato deseado
      let formattedDate = date.toLocaleString("en-US", options);

      // Reemplazar la coma y agregar "pm" si la hora es después del mediodía
      formattedDate = formattedDate.replace(",", "");
      // if (date.getHours() >= 12) {
      //   formattedDate += " pm";
      // } else {
      //   formattedDate += " am";
      // }
      // console.log("formattedDate", formattedDate);
      return formattedDate;
    },
    close() {
      this.$emit("dialog:change", false);
    },
    async HandlerShowContent(item) {
      try {
        this.loading = true;
        const request = { content_id: item.content_id };
        const response = await this.$store.dispatch(
          "profile/ENCRYPT_CONTENT",
          request
        );

        const requestNotification = { id: item.id, status: item.status };
        await this.$store.dispatch(
          "profile/UPDATE_NOTIFICATION",
          requestNotification
        );

        const data_encrypt = response.data.public_key;

        const encryptData = this.CryptoJS.AES.encrypt(
          data_encrypt,
          "MyEncrypt-dAtA-sEcrEt"
        ).toString();

        sessionStorage.setItem("Value-rec", encryptData);
        this.$router.push({
          name: "show_content",
          query: { index: response.data.data_encrypt },
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.containerListnot {
  position: absolute;
  top: 0;
  width: 450px;
  right: 0;
  height: 100vh;
  overflow: auto;
  background: #ffffff !important;
  padding: 0 !important;
  color: #000000;
}
.showNotificationsDad {
  overflow: auto;
  min-height: 100vh;
  width: clamp(320px, 50%, 450px);
}
.stylesTimestampAlert {
  margin-top: 0.5rem;
  margin-left: 2rem;
  /* background-color: red; */
  text-align: left;
  font: normal normal 300 13px/21px Poppins;
  letter-spacing: 0px;
  color: #8a98a5;
}
.alignCenter {
  align-items: center;
}
.circleAlertNotify-filled {
  width: 10px;
  border-radius: 1000px;
  height: 10px;
  margin-right: 0.5rem;
  background: #0076ff;
}

.circleAlertNotify-vacum {
  width: 1rem;
  border-radius: 1000px;
  height: 1rem;
  margin-right: 0.5rem;
  background: #d2d2d2;
}
.alertNotification {
  /* background: red; */
  /* height: 64px; */
  padding: 1rem;
  font-weight: bold;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: white;
  flex-direction: column;
  border-radius: 0.2rem;
}
.dialogNotification-bg {
  background: #f6f6f6 0% 0% no-repeat padding-box !important;
  /* height: 91vh; */
  min-height: calc(100vh - 42px);
  padding: 1rem;
  /* padding-top: 1rem;
  margin-top: 2rem; */
}
.dialogNotification {
  /* height: 100%; */
  /* background: red; */
  display: flex;
  justify-content: flex-end;
  /* left: 0; */
  min-height: 100vh;
  top: 0px;
  right: 0px;
  /* right: calc(-69vw); */
  position: absolute;
  width: 100%;
  /* margin-left: -23px; */
  /* margin: 0 16px;
}
.v-dialog {
  border-radius: 4px !important;
  margin: 24px !important;
  overflow-y: auto !important;
  pointer-events: auto !important;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) !important;
  width: 100% !important;
  z-index: inherit !important;
  box-shadow: 10px 10px 5px -15px rgba(0, 0, 0, 0.75) !important;
  /* box-shadow: 0px !important; */
}
</style>
