<template>
  <div>
    <toolbar-core />
    <v-container>
      <v-row justify="center" class="mt-5 mt-md-0">
        <v-col cols="12" class="pt-0">
          <v-btn
            @click="$router.go(-1)"
            color="#0CAAC8"
            text
            rounded
            class="text-sub-title text-capitalize mt-5 mt-md-0"
          >
            <v-icon large class="mr-3">mdi-arrow-left-drop-circle</v-icon>
            Volver
          </v-btn>
        </v-col>
        <v-col cols="12" md="8">
          <ValidationObserver ref="obs" v-slot="{ passes }">
            <v-card :loading="loading" class="custom-card py-10 px-10">
              <v-card-text>
                <h2 class="text-center">
                  Seleccione el plan de su preferencia
                </h2>
                <v-row justify="center" class="mt-5">
                  <v-col cols="12" sm="6" md="6">
                    <v-select
                      :items="plans"
                      item-text="name"
                      item-value="id"
                      class="mt-3"
                      solo
                      v-model="plan_id"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="12" class="d-flex justify-center">
                    <v-btn
                      @click="passes(HandlerVerify)"
                      :disabled="isDisabled"
                      rounded
                      color="#0DAAC9"
                      class="font-light white--text"
                    >
                      Continuar
                    </v-btn>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-row>
                      <v-col v-show="showMethodToPay" cols="12" md="12">
                        <h3 class="text-center">Continua con tu pago</h3>
                        <div class="mt-5" id="paypal-button-container"></div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </ValidationObserver>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
// import axios from "axios";
import Notification from "../Utils/notification";
import ToolbarCore from "../core/Toolbar";
export default {
  components: {
    "toolbar-core": ToolbarCore,
  },
  data() {
    return {
      country_id: "",
      phone_number: "",
      password: "",
      showMethodToPay: false,
      response: {},
      loading: false,
      plan: {},
      authUser: {},
      plans: [],
      plan_id: "",
      amount: "",
      isDisabled: true,
    };
  },

  created() {
    this.getPlans();
  },

  computed: {
    getAuthUser() {
      return this.$store.getters["auth/AUTH_USER"];
    },
    getPlansUser() {
      return this.$store.getters["auth/PLANS"];
    },
  },

  watch: {
    plan_id() {
      this.showMethodToPay = false;
      this.isDisabled = this.plan_id ? false : true;
    },
  },

  methods: {
    HandlerRoute(name, value) {
      this.$router.push({ name: name });
      if (value == "price") {
        // window.location.href = "#price";
        window.open(
          "#price",
          "_blank" // <- This is what makes it open in a new window.
        );
      }
    },

    async getPlans() {
      try {
        const response = await this.$store.dispatch("profile/PLAN_SERVICES");
        const { data: plans } = response.data;
        if (plans.length) {
          this.plans = plans.map((index) => {
            return {
              id: index.id,
              name: `${index.name} - $${index.price}`,
            };
          });
          this.plan_id = this.getPlansUser ? this.getPlansUser.id : null;
          this.isDisabled = this.plan_id ? false : true;
        }
      } catch (error) {
        console.log(error);
      }
    },

    async HandlerVerify() {
      try {
        this.loading = true;
        const currentPlan = this.plans.find(
          (element) => element.id == this.plan_id
        );

        this.amount = currentPlan.name
          .split("-")[1]
          .trim()
          .replace("$", "");

        this.showMethodToPay = true;
        this.$snotify.success("Listo para realizar el pago", "Exitos!");
        this.HandlerPay();
      } catch (error) {
        if (error.response.status == 422) {
          let e = error.response.data.error;
          let showNotification = new Notification(e);
          showNotification.notification;
        } else {
          this.$snotify.error(error.response.data.message, "Error!");
        }
      } finally {
        this.loading = false;
      }
    },

    HandlerCountryResponse(val) {
      this.country_id = val;
    },

    HandlerPay() {
      document.getElementById("paypal-button-container").innerHTML = "";
      const plan_id = this.plan_id;
      const amount = this.amount;
      const country_id = this.getAuthUser.countries.id;
      const phone_number = this.getAuthUser.phone_number;
      let that = this;
      window.paypal
        .Buttons({
          style: {
            layout: "vertical",
            color: "gold",
            shape: "pill",
            label: "paypal",
          },
          createOrder: function(data, actions) {
            // This function sets up the details of the transaction, including the amount and line item details.
            return actions.order.create({
              purchase_units: [
                {
                  amount: {
                    value: amount,
                  },
                },
              ],
            });
          },
          onApprove: function(data, actions) {
            // This function captures the funds from the transaction.
            return actions.order.capture().then(function(details) {
              const request = {
                plan_id: plan_id,
                country_id: country_id,
                phone_number: phone_number,
                payment: details,
              };
              that.HandlerSendPay(request);
              that.$snotify.success(
                "Tu pago se ha realizados con exitos porfavor espera mientras terminaos con el proceso",
                "¡Exitos!"
              );
            });
          },
          onError: (err) => {
            console.error("error from the onError callback", err);
            that.$snotify.error(
              "Ha ocurrido un error comunicate con el administrador del sistema",
              "¡Error!"
            );
          },
        })
        .render("#paypal-button-container");
    },

    async HandlerSendPay(request) {
      try {
        const data = { ...request };
        await this.$store.dispatch("profile/NEW_SEND_RESPONSE_PAY", data);
        console.log(request);
        setTimeout(() => {
          this.$router.push({ name: "profile" });
        }, 1500);
      } catch (error) {
        if (error.response.status == 422) {
          let e = error.response.data.error;
          let showNotification = new Notification(e);
          showNotification.notification;
        } else {
          this.$snotify.error(error.response.data.message, "Error!");
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style></style>
