<template>
  <v-responsive>
    <dashboard-new :level-user-percentage="levelUserPercentage.toFixed(1)">
      <template v-slot:header>
        <toolbar-core />
      </template>
      <template v-slot:body>
        <div
          class="ml-10 classContainerBodySlot"
          style="width: calc(100vw - 630px); min-height: calc(100vh - 190px);"
        >
          <h1 class="tittleMyProfile2">
            Mi perfil
          </h1>
          <p class="titleMyProfile">
            Panel / Mi Perfil
          </p>
          <div class="containerImage">
            <!-- <button @click="showDialogEdit(0)" class="btnEditProfile2">
              Editar perfil
            </button> -->
            <div @click="$refs.inputUpload.click()">
              <img
                v-if="getAuthUser.whatsapp_account_image_profiles == null"
                src="@/assets/img/userDefault2.svg"
                alt="userDefault"
              />
              <v-avatar
                v-else
                @click="$refs.inputUpload.click()"
                v-bind="attrs"
                v-on="on"
                color="white"
                size="60"
              >
                <img
                  :src="getAuthUser.whatsapp_account_image_profiles.file_upload"
                  alt="profile avatar"
                />
              </v-avatar>
            </div>
            <div class="ml-5 classMyProfileInfoDiv" style="width: 100%">
              <span
                style="
              text-align: left;
              font: normal normal 600 18px/27px Poppins;
              letter-spacing: 0px;
              padding:0;
              color: #101820;"
              >
                {{ getAuthUser.name + " " + getAuthUser.last_name }}
              </span>
              <div class="d-flex containerNumberFlag">
                <v-avatar size="22"><img :src="countryUserFlag"/></v-avatar>
                <span>{{ countryUserCode }} {{ phone_number }}</span>
              </div>
              <div class="classMyProfileInfoDiv2">
                <div class="classMyProfileInfoDiv3">
                  <div class="containerData">
                    <span class="titleSpan fontWBold">
                      <span v-if="Object.values(getAuthUser).length">
                        <span v-if="getAuthUser.modules != null">
                          {{ getAuthUser.modules.name.toUpperCase() }}
                        </span>
                      </span>
                    </span>
                    <span class="titleSpan">Programa actual</span>
                  </div>
                  <div class="containerData">
                    <span class="titleSpan fontWBold">
                      <span v-if="Object.values(getAuthUser).length > 0">
                        {{ getAuthUser.level.toUpperCase() | nameTilde }}
                      </span>
                    </span>
                    <span class="titleSpan">Recorrido en Desarrollo</span>
                  </div>
                  <div class="containerData">
                    <span class="titleSpan fontWBold">
                      <span v-if="Object.values(getAuthUser).length > 0">
                        {{ HandlerGetHours() }}
                      </span>
                    </span>
                    <span class="titleSpan">Hrs. Consumidas</span>
                  </div>
                </div>
                <div style="width: 200px;">
                  <p
                    style="text-align: left;
                    display: flex;
                    justify-content: space-between;
                    font: normal normal normal 13px/20px Poppins;
                    letter-spacing: 0px;
                    color: #101820;"
                  >
                    <span>Perfil completado</span>
                    <span>{{ levelUserPercentage.toFixed(1) }}</span>
                  </p>
                  <loader-unicolor
                    :percentage="levelUserPercentage.toFixed(1)"
                  ></loader-unicolor>
                </div>
              </div>
            </div>
          </div>
          <div class="containerImage containerImage--ext1 mt-5 bg_none">
            <div class="conainerMyData">
              <div class="containerTitlesBtnMyData">
                <h1 class="title3">Mis Datos</h1>
                <button @click="showDialogEdit(0)" class="btnEditProfile">
                  Editar perfil
                </button>
              </div>
              <v-divider></v-divider>
              <div class="d-flex">
                <div class="d-flex flex-column columnDatesHeaders">
                  <span>ID Usuario</span>
                  <span>Usuario</span>
                  <span>Celular</span>
                  <span>Nombres</span>
                  <span>Apellidos</span>
                </div>
                <div class="d-flex flex-column columnDatesValues">
                  <span>{{ idUser }}</span>
                  <span>{{ nickname }}</span>
                  <span>{{ countryUserCode }} {{ phone_number }}</span>
                  <span>{{ name }}</span>
                  <span>{{ last_name }}</span>
                </div>
              </div>
            </div>
            <div class="detailsAccountContainer">
              <div class="containerTitlesBtnMyData2">
                <span class="detailAccountTitle">Módulo Actual</span>
                <button @click="showDialogEdit(2)" class="btnEditProfile">
                  Editar perfil
                </button>
              </div>
              <v-divider></v-divider>

              <div class="d-flex">
                <div class="d-flex flex-column columnDatesHeaders">
                  <span>
                    <v-icon class="mr-5">mdi-trending-up</v-icon>
                    <span class="titleSpan">Programa Actual</span>
                  </span>
                  <span>
                    <div class="d-flex alignCenterTr ">
                      <v-icon class="mr-5">mdi-airplane-takeoff</v-icon>
                      <div class="d-flex flex-column">
                        <span class="titleSpan">Recorrido en</span>
                        <span class="titleSpan">desarrollo</span>
                      </div>
                    </div>
                  </span>
                  <span>
                    <v-icon class="mr-5">mdi-timer</v-icon>
                    <span class="titleSpan">Hrs. Consumidas</span></span
                  >
                </div>
                <div class="d-flex flex-column columnDatesValues">
                  <span v-if="Object.values(getAuthUser).length">
                    <span
                      v-if="getAuthUser.modules != null"
                      class="cellMiddleDinamyc"
                    >
                      {{ getAuthUser.modules.name.toUpperCase() }}
                    </span>
                  </span>
                  <span
                    v-if="Object.values(getAuthUser).length > 0"
                    class="cellMiddleDinamyc"
                    style="height: 40px;"
                  >
                    {{ getAuthUser.level.toUpperCase() | nameTilde }}
                  </span>
                  <span>
                    <span
                      v-if="Object.values(getAuthUser).length > 0"
                      class="cellMiddleDinamyc"
                    >
                      {{ HandlerGetHours() }}
                    </span></span
                  >
                </div>
              </div>

              <table>
                <tr class="trCustom">
                  <td></td>
                  <td class="px-5"></td>
                  <!-- <td class="trPadding">
                    <div class="divPencilRound">
                      <v-icon>mdi-pencil</v-icon>
                    </div>
                  </td> -->
                </tr>
                <div class="dividerCustom">
                  <v-divider></v-divider>
                </div>
                <tr class="trCustom">
                  <td></td>
                  <td class="px-5"></td>
                  <!-- <td class="trPadding">
                    <div class="divPencilRound">
                      <v-icon>mdi-pencil</v-icon>
                    </div>
                  </td> -->
                </tr>
              </table>
            </div>
          </div>
          <div class="setExperience">
            <p class="setExperience_title">Ajuste de experiencia</p>
            <v-divider></v-divider>
            <div class="d-flex containerSetExperience_child">
              <v-btn @click="showDialogEdit(3)">
                <v-icon>mdi-timer</v-icon>
              </v-btn>
              <span class="containerSetExperience_child_text"
                >Horas preferidas</span
              >
              <span>{{ getAuthUser.time }}</span>
            </div>
            <div class="d-flex containerSetExperience_child">
              <v-btn @click="showSelectDays = true">
                <v-icon>mdi-calendar-blank</v-icon>
              </v-btn>
              <span class="containerSetExperience_child_text"
                >Días de preferencias</span
              >
              <p style="padding: 0; margin: 0;">
                <span
                  v-for="(item, index) in JSON.parse(getAuthUser.days)"
                  :key="index"
                >
                  {{ item }}
                </span>
              </p>
            </div>
            <div class="d-flex containerSetExperience_child">
              <v-icon>mdi-earth</v-icon>
              <span class="containerSetExperience_child_text"
                >Zona horaria elegida (GMT)</span
              >
              <div class="zoneHour">
                <v-select
                  label="Zona horaria"
                  :items="countries_gtm"
                  :item-value="(item) => item"
                  item-text="name"
                  v-model="gtm"
                  outlined
                  dense
                  :loading="loadinSelect"
                  @change="HandlerEditProfile"
                ></v-select>
              </div>
            </div>
          </div>
        </div>
      </template>
    </dashboard-new>
    <v-dialog v-model="dialogEditProfile" width="unset">
      <div class="styleesdialogEditProfile">
        <buton
          @click="dialogEditProfile = false"
          class="btnCloseDialogEditProfileContainer"
        >
          <v-icon>mdi-close</v-icon>
        </buton>
        <!-- Cp3 -->
        <div v-if="formDialogCount == 2" class="mx-1 mx-md-auto">
          <div class="divInsideVDialogEditUSer">
            <div class="mb-md-4">
              <div class="d-flex">
                <v-img
                  class="mt-0"
                  contain
                  max-width="25px"
                  src="@/assets/img/cubes.svg"
                ></v-img>
                <p class="ml-2 my-0">
                  <span class="font-weight-bold">PROGRAMA ACTUAL:</span>
                </p>
              </div>
              <span v-if="Object.values(getAuthUser).length">
                <span
                  v-if="getAuthUser.modules != null"
                  style="color: #5da9ff"
                  class="ml-8 mt-0 pt-0 font-weight-medium"
                >
                  {{ getAuthUser.modules.name.toUpperCase() }}
                </span>
              </span>
              <p
                v-if="!getAuthUser.company.length"
                @click="showSelectModule = !showSelectModule"
                style="font-size: 12px; color: #e05492; cursor: pointer"
                class="text-end"
              >
                MODIFICAR
              </p>
            </div>
            <v-divider></v-divider>
            <div class="my-4">
              <div class="d-flex">
                <v-img
                  class="mt-0"
                  contain
                  max-width="25px"
                  src="@/assets/img/fi-rr-paper-plane.svg"
                ></v-img>
                <p class="ml-2 my-0">
                  <span class="font-weight-bold">
                    RECORRIDO EN DESARROLLO:
                  </span>
                </p>
              </div>

              <span
                v-if="Object.values(getAuthUser).length > 0"
                style="color: #5da9ff"
                class="ml-8 mt-0 pt-0 font-weight-medium"
              >
                {{ getAuthUser.level.toUpperCase() | nameTilde }}
              </span>
              <p
                v-if="!getAuthUser.company.length"
                @click="showSelectModule = !showSelectModule"
                style="font-size: 12px; color: #e05492; cursor: pointer"
                class="text-end"
              >
                MODIFICAR
              </p>
            </div>
            <v-divider></v-divider>
            <div class="divHrsInnersEditDialog">
              <div class="d-flex">
                <v-img
                  class="mt-0"
                  contain
                  max-width="25px"
                  src="@/assets/img/reloj-de-pared.svg"
                ></v-img>
                <p class="ml-2 my-0">
                  <span class="font-weight-bold">
                    HORAS CONSUMIDAS:
                  </span>
                </p>
              </div>
              <span
                v-if="Object.values(getAuthUser).length > 0"
                style="color: #5da9ff"
                class="ml-8 mt-0 pt-0 font-weight-medium"
              >
                {{ HandlerGetHours() }}
              </span>
            </div>
            <div v-if="showSelectModule">
              <v-divider class="mt-10"></v-divider>
              <div class="d-flex mt-4">
                <v-img
                  class="mt-0"
                  contain
                  max-width="25px"
                  src="@/assets/img/edit.svg"
                ></v-img>
                <p class="ml-2 my-0">
                  <span class="font-weight-bold">
                    Modificar Módulo y Nivel:
                  </span>
                </p>
              </div>
              <ValidationObserver ref="obs" v-slot="{ passes }">
                <ValidationProvider
                  name="modulo"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-select
                    :items="modules"
                    item-value="id"
                    item-text="name"
                    v-model="moduleId"
                    class="mx-15 px-3"
                    outlined
                    dense
                    :error-messages="errors"
                  ></v-select>
                </ValidationProvider>
                <ValidationProvider
                  name="nivel"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-select
                    :items="levels"
                    v-model="levelId"
                    item-value="name"
                    item-text="name_false"
                    class="mx-15 px-3"
                    outlined
                    dense
                    :error-messages="errors"
                  ></v-select>
                </ValidationProvider>
                <div>
                  <p
                    @click="passes(HandlerSaveModule)"
                    style="
                              font-size: 12px;
                              color: #e05492;
                              cursor: pointer;
                            "
                    class="text-end"
                  >
                    EDITAR
                  </p>
                </div>
              </ValidationObserver>
            </div>
          </div>
        </div>
        <!-- Cp2 -->
        <div v-if="formDialogCount == 0">
          <div class="d-flex justify-space-between px-5 py-5">
            <h3 style="font-weight: bold;">
              Perfil de <span class="text-capitalize">{{ name }}</span>
            </h3>
          </div>

          <ValidationObserver ref="obs">
            <div class="">
              <div v-if="getAuthUser.company.length">
                <span style="font-size: 12px">Empresa</span>
                <v-text-field
                  v-model="company"
                  dense
                  label="Empresa"
                  outlined
                  disabled
                ></v-text-field>
              </div>
              <div>
                <span style="font-size: 12px">Nombre</span>
                <ValidationProvider
                  name="nombre"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    v-model="name"
                    dense
                    label="Nombre"
                    outlined
                    disabled
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
              </div>
              <div>
                <span style="font-size: 12px">Apellido</span>
                <ValidationProvider
                  name="apellido"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    v-model="last_name"
                    dense
                    label="Apellido"
                    outlined
                    disabled
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
              </div>
              <div>
                <span style="font-size: 12px">Usuario</span>
                <ValidationProvider
                  name="usuario"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    v-model="nickname"
                    dense
                    label="Usuario"
                    outlined
                    disabled
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
              </div>
              <!-- <div>
                <span style="font-size: 12px">Pais</span>
                <country-autocomplete
                  :small="true"
                  :profile="profileData"
                  :isProfile="isProfile"
                  :disabled="true"
                />
              </div> -->
              <div>
                <span style="font-size: 12px">Celular</span>
                <ValidationProvider
                  name="telefono"
                  rules="required|numeric"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    v-model="phone_number"
                    dense
                    label="Celular"
                    outlined
                    disabled
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
              </div>
              <div>
                <span style="font-size: 12px">Contraseña actual</span>
                <ValidationProvider
                  name="contraseña actual"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    type="password"
                    v-model="old_password"
                    label="*******"
                    dense
                    outlined
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
              </div>
              <div>
                <span style="font-size: 12px">Contraseña nueva</span>
                <ValidationProvider
                  name="contraseña nueva"
                  :rules="{
                    required: old_password !== '' ? true : false,
                  }"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    type="password"
                    v-model="new_password"
                    label="*******"
                    dense
                    outlined
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
              </div>
            </div>
          </ValidationObserver>
        </div>
        <!-- Cp1  -->
        <!-- <div v-if="formDialogCount == 2">
          <p
            style="color: #061f42; font-size: 18px"
            class="font-weight-bold text-center mt-8"
          >
            Personaliza tu experiencia
          </p>
          <v-card :loading="loading" class="mx-1 mx-md-auto card-custom">
            <v-card-text>
              <div class="d-flex">
                <v-menu
                  ref="menu"
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="time"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-img
                      style="cursor: pointer"
                      v-bind="attrs"
                      v-on="on"
                      class="mt-0"
                      contain
                      max-width="25px"
                      src="@/assets/img/reloj-de-pared.svg"
                    ></v-img>
                  </template>
                  <v-time-picker
                    color="#0DB2D2"
                    ampm-in-title
                    v-if="menu2"
                    v-model="time"
                    full-width
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu2 = false">
                      Cancelar
                    </v-btn>
                    <v-btn text color="primary" @click="HandlerEditProfile">
                      Guardar
                    </v-btn>
                  </v-time-picker>
                </v-menu>

                <p class="ml-2 mt-4">
                  <span class="font-weight-bold">Horas preferidas:</span>
                  {{ getAuthUser.time }}
                </p>
              </div>
              <v-divider></v-divider>
              <div class="d-flex" v-if="Object.values(getAuthUser).length > 0">
                <v-icon
                  style="cursor: pointer"
                  @click="showSelectDays = !showSelectDays"
                  color="black"
                  size="30"
                  >mdi-calendar-text</v-icon
                >
                <p class="ml-2 mt-4">
                  <span class="font-weight-bold">
                    Días de preferencias:
                  </span>
                  <span
                    v-for="(item, index) in JSON.parse(getAuthUser.days)"
                    :key="index"
                  >
                    {{ item }}
                  </span>
                </p>
              </div>
              <div>
                <div class="font-weight-bold">
                  Zona horaria elegida (GMT): {{ gtm_value }}
                </div>
                <br />
                <v-select
                  label="Zona horaria"
                  :items="countries_gtm"
                  :item-value="(item) => item"
                  item-text="name"
                  v-model="gtm"
                  class="mx-15 px-3"
                  outlined
                  dense
                  :loading="loadinSelect"
                  @change="HandlerEditProfile"
                ></v-select>
              </div>
            </v-card-text>
          </v-card>
        </div> -->
        <v-time-picker v-if="formDialogCount == 3" v-model="time">
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="dialogEditProfile = false">
            Cancelar
          </v-btn>
          <v-btn text color="primary" @click="HandlerEditProfile">
            Guardar
          </v-btn>
        </v-time-picker>

        <v-btn
          :loading="loading"
          @click="HandlerEditProfile"
          class="saveEdit"
          dark
          color="#101820"
          rounded
          >GUARDAR</v-btn
        >
      </div>
    </v-dialog>

    <select-days-component
      v-if="showSelectDays"
      :showSelectDays="showSelectDays"
      :selectDays="getAuthUser.days"
      @dialog:change="HandlerResponseDays"
    />

    <input
      v-show="false"
      ref="inputUpload"
      type="file"
      @change="HandlerGetPhoto"
    />
    <!-- <v-container>
      <v-row justify="center">
        <v-col cols="12" class="pt-0">
          <v-btn
            @click="$router.go(-1)"
            color="#0CAAC8"
            text
            rounded
            class="text-sub-title text-capitalize mt-5 mt-md-0"
          >
            <v-icon large class="mr-3">mdi-arrow-left-drop-circle</v-icon>
            Volver
          </v-btn>
        </v-col>
        <v-col cols="12" sm="12" md="10" class="mt-5">
          <v-card
            v-if="Object.values(getAuthUser).length > 0"
            color="#EEEEEE"
            class="pb-15 card-custom pt-13 px-md-13"
          >
            <v-row>
              <v-col cols="12" md="6">
                <v-card
                  :loading="loading"
                  height="150"
                  class="card-custom mx-1 mx-md-0"
                >
                  <v-img
                    style="cursor: pointer"
                    height="150"
                    src="@/assets/img/Mask Group 21.png"
                    alt="imagen principal"
                    dark
                  >
                    <div class="d-flex justify-center mt-9">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-avatar
                            @click="$refs.inputUpload.click()"
                            v-bind="attrs"
                            v-on="on"
                            color="white"
                            size="60"
                          >
                            <v-icon
                              v-if="
                                getAuthUser.whatsapp_account_image_profiles ==
                                  null
                              "
                              color="primary"
                              >mdi-account</v-icon
                            >
                            <img
                              v-else
                              :src="
                                getAuthUser.whatsapp_account_image_profiles
                                  .file_upload
                              "
                              alt="profile avatar"
                            />
                          </v-avatar>
                        </template>
                        <span>Actualizar Imagen</span>
                      </v-tooltip>
                      <input
                        v-show="false"
                        ref="inputUpload"
                        type="file"
                        @change="HandlerGetPhoto"
                      />
                      <span class="white--text align-self-center ml-3 title">
                        {{ getAuthUser.name }}
                        <span class="font-weight-bold">
                          {{ getAuthUser.last_name }}
                        </span>
                      </span>
                    </div>
                  </v-img>
                </v-card>
              </v-col>
              <v-col cols="12" md="6" class="pt-0">
                <v-card class="mx-1 mx-md-auto card-custom">
                  <v-card-text>
                    <div class="mb-md-4">
                      <div class="d-flex">
                        <v-img
                          class="mt-0"
                          contain
                          max-width="25px"
                          src="@/assets/img/cubes.svg"
                        ></v-img>
                        <p class="ml-2 my-0">
                          <span class="font-weight-bold">PROGRAMA ACTUAL:</span>
                        </p>
                      </div>
                      <span v-if="Object.values(getAuthUser).length">
                        <span
                          v-if="getAuthUser.modules != null"
                          style="color: #5da9ff"
                          class="ml-8 mt-0 pt-0 font-weight-medium"
                        >
                          {{ getAuthUser.modules.name.toUpperCase() }}
                        </span>
                      </span>
                      <p
                        v-if="!getAuthUser.company.length"
                        @click="showSelectModule = !showSelectModule"
                        style="font-size: 12px; color: #e05492; cursor: pointer"
                        class="text-end"
                      >
                        MODIFICAR
                      </p>
                    </div>
                    <v-divider></v-divider>
                    <div class="my-4">
                      <div class="d-flex">
                        <v-img
                          class="mt-0"
                          contain
                          max-width="25px"
                          src="@/assets/img/fi-rr-paper-plane.svg"
                        ></v-img>
                        <p class="ml-2 my-0">
                          <span class="font-weight-bold">
                            RECORRIDO EN DESARROLLO:
                          </span>
                        </p>
                      </div>

                      <span
                        v-if="Object.values(getAuthUser).length > 0"
                        style="color: #5da9ff"
                        class="ml-8 mt-0 pt-0 font-weight-medium"
                      >
                        {{ getAuthUser.level.toUpperCase() | nameTilde }}
                      </span>
                      <p
                        v-if="!getAuthUser.company.length"
                        @click="showSelectModule = !showSelectModule"
                        style="font-size: 12px; color: #e05492; cursor: pointer"
                        class="text-end"
                      >
                        MODIFICAR
                      </p>
                    </div>
                    <v-divider></v-divider>
                    <div class="mt-4">
                      <div class="d-flex">
                        <v-img
                          class="mt-0"
                          contain
                          max-width="25px"
                          src="@/assets/img/reloj-de-pared.svg"
                        ></v-img>
                        <p class="ml-2 my-0">
                          <span class="font-weight-bold">
                            HORAS CONSUMIDAS:
                          </span>
                        </p>
                      </div>
                      <span
                        v-if="Object.values(getAuthUser).length > 0"
                        style="color: #5da9ff"
                        class="ml-8 mt-0 pt-0 font-weight-medium"
                      >
                        {{ HandlerGetHours() }}
                      </span>
                    </div>
                    <div v-if="showSelectModule">
                      <v-divider class="mt-10"></v-divider>
                      <div class="d-flex mt-4">
                        <v-img
                          class="mt-0"
                          contain
                          max-width="25px"
                          src="@/assets/img/edit.svg"
                        ></v-img>
                        <p class="ml-2 my-0">
                          <span class="font-weight-bold">
                            Modificar Módulo y Nivel:
                          </span>
                        </p>
                      </div>
                      <ValidationObserver ref="obs" v-slot="{ passes }">
                        <ValidationProvider
                          name="modulo"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <v-select
                            :items="modules"
                            item-value="id"
                            item-text="name"
                            v-model="moduleId"
                            class="mx-15 px-3"
                            outlined
                            dense
                            :error-messages="errors"
                          ></v-select>
                        </ValidationProvider>
                        <ValidationProvider
                          name="nivel"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <v-select
                            :items="levels"
                            v-model="levelId"
                            item-value="name"
                            item-text="name_false"
                            class="mx-15 px-3"
                            outlined
                            dense
                            :error-messages="errors"
                          ></v-select>
                        </ValidationProvider>
                        <div>
                          <p
                            @click="passes(HandlerSaveModule)"
                            style="
                              font-size: 12px;
                              color: #e05492;
                              cursor: pointer;
                            "
                            class="text-end"
                          >
                            EDITAR
                          </p>
                        </div>
                      </ValidationObserver>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row class="mt-0 mb-15">
              <v-col cols="12" md="6" class="pr-0 mt-md-n15">
                <p
                  style="color: #061f42; font-size: 18px"
                  class="text-center font-weight-bold mt-0"
                >
                  Perfil de <span class="text-capitalize">{{ name }}</span>
                </p>
                <ValidationObserver ref="obs" v-slot="{ passes }">
                  <v-card
                    class="card-custom mx-1 mr-4 mr-md-0 mr-md-2 mx-md-auto"
                  >
                    <v-card-text>
                      <v-row
                        no-gutters
                        class="mb-md-0 mt-md-2"
                        v-if="getAuthUser.company.length"
                      >
                        <v-col class="mt-md-2" cols="12" md="5">
                          <span style="font-size: 12px">Empresa</span>
                        </v-col>
                        <v-col cols="12" md="7">
                          <v-text-field
                            v-model="company"
                            dense
                            label="SMITH"
                            outlined
                            disabled
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row no-gutters class="mb-md-0 mt-md-2">
                        <v-col class="mt-md-2" cols="12" md="5">
                          <span style="font-size: 12px">Nombre</span>
                        </v-col>
                        <v-col cols="12" md="7">
                          <ValidationProvider
                            name="nombre"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <v-text-field
                              v-model="name"
                              dense
                              label="JOHN"
                              solo
                              disabled
                              :error-messages="errors"
                            ></v-text-field>
                          </ValidationProvider>
                        </v-col>
                      </v-row>
                      <v-row no-gutters class="mb-md-0 mt-md-2">
                        <v-col class="mt-md-2" cols="12" md="5">
                          <span style="font-size: 12px">Apellido</span>
                        </v-col>
                        <v-col cols="12" md="7">
                          <ValidationProvider
                            name="apellido"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <v-text-field
                              v-model="last_name"
                              dense
                              label="SMITH"
                              solo
                              disabled
                              :error-messages="errors"
                            ></v-text-field>
                          </ValidationProvider>
                        </v-col>
                      </v-row>
                      <v-row no-gutters class="mb-md-0 mt-md-2">
                        <v-col class="mt-md-2" cols="12" md="5">
                          <span style="font-size: 12px">Usuario</span>
                        </v-col>
                        <v-col cols="12" md="7">
                          <ValidationProvider
                            name="usuario"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <v-text-field
                              v-model="nickname"
                              dense
                              label="JhonSmith"
                              solo
                              disabled
                              :error-messages="errors"
                            ></v-text-field>
                          </ValidationProvider>
                        </v-col>
                      </v-row>
                      <v-row no-gutters class="mb-md-0 mt-md-2">
                        <v-col class="mt-md-2" cols="12" md="5">
                          <span style="font-size: 12px">Celular</span>
                        </v-col>
                        <v-col cols="12" md="3" class="mt-md-n3">
                          <country-autocomplete
                            :small="true"
                            :profile="profileData"
                            :isProfile="isProfile"
                            :disabled="true"
                          />
                        </v-col>
                        <v-col cols="12" md="4">
                          <ValidationProvider
                            name="telefono"
                            rules="required|numeric"
                            v-slot="{ errors }"
                          >
                            <v-text-field
                              v-model="phone_number"
                              dense
                              label="+13102597522"
                              solo
                              disabled
                              :error-messages="errors"
                            ></v-text-field>
                          </ValidationProvider>
                        </v-col>
                      </v-row>
                      <v-row no-gutters class="mb-md-0 mt-md-2">
                        <v-col class="mt-md-2" cols="12" md="5">
                          <span style="font-size: 12px">Contraseña actual</span>
                        </v-col>
                        <v-col cols="12" md="7">
                          <ValidationProvider
                            name="contraseña actual"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <v-text-field
                              type="password"
                              v-model="old_password"
                              label="*******"
                              dense
                              solo
                              :error-messages="errors"
                            ></v-text-field>
                          </ValidationProvider>
                        </v-col>
                      </v-row>
                      <v-row no-gutters class="mb-md-0 mt-md-2">
                        <v-col class="mt-md-2" cols="12" md="5">
                          <span style="font-size: 12px">Contraseña nueva</span>
                        </v-col>
                        <v-col cols="12" md="7">
                          <ValidationProvider
                            name="contraseña nueva"
                            :rules="{
                              required: old_password !== '' ? true : false,
                            }"
                            v-slot="{ errors }"
                          >
                            <v-text-field
                              type="password"
                              v-model="new_password"
                              label="*******"
                              dense
                              solo
                              :error-messages="errors"
                            ></v-text-field>
                          </ValidationProvider>
                        </v-col>
                      </v-row>
                      <v-row justify="center">
                        <v-btn
                          :loading="loading"
                          @click="passes(HandlerEditProfile)"
                          class="my-7"
                          dark
                          color="#0DAAC9"
                          rounded
                          >GUARDAR</v-btn
                        >
                      </v-row>
                    </v-card-text>
                  </v-card>
                </ValidationObserver>
              </v-col>
              <v-col cols="12" md="6" class="pt-0">
                <p
                  style="color: #061f42; font-size: 18px"
                  class="font-weight-bold text-center mt-8"
                >
                  Personaliza tu experiencia
                </p>
                <v-card :loading="loading" class="mx-1 mx-md-auto card-custom">
                  <v-card-text>
                    <div class="d-flex">
                      <v-menu
                        ref="menu"
                        v-model="menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="time"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-img
                            style="cursor: pointer"
                            v-bind="attrs"
                            v-on="on"
                            class="mt-0"
                            contain
                            max-width="25px"
                            src="@/assets/img/reloj-de-pared.svg"
                          ></v-img>
                        </template>

                      </v-menu>

                      <p class="ml-2 mt-4">
                        <span class="font-weight-bold">Horas preferidas:</span>
                        {{ getAuthUser.time }}
                      </p>
                    </div>
                    <v-divider></v-divider>
                    <div
                      class="d-flex"
                      v-if="Object.values(getAuthUser).length > 0"
                    >
                      <v-icon
                        style="cursor: pointer"
                        @click="showSelectDays = !showSelectDays"
                        color="black"
                        size="30"
                        >mdi-calendar-text</v-icon
                      >
                      <p class="ml-2 mt-4">
                        <span class="font-weight-bold">
                          Días de preferencias:
                        </span>
                        <span
                          v-for="(item, index) in JSON.parse(getAuthUser.days)"
                          :key="index"
                        >
                          {{ item }}
                        </span>
                      </p>
                    </div>
                    <div>
                      <div class="font-weight-bold">
                        Zona horaria elegida (GMT): {{ gtm_value }}
                      </div>
                      <br />
                      <v-select
                        label="Zona horaria"
                        :items="countries_gtm"
                        :item-value="(item) => item"
                        item-text="name"
                        v-model="gtm"
                        class="mx-15 px-3"
                        outlined
                        dense
                        :loading="loadinSelect"
                        @change="HandlerEditProfile"
                      ></v-select>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <select-days-component
      v-if="showSelectDays"
      :showSelectDays="showSelectDays"
      :selectDays="getAuthUser.days"
      @dialog:change="HandlerResponseDays"
    /> -->
  </v-responsive>
</template>

<script>
import Notification from "../Utils/notification";
import ToolbarCore from "../core/Toolbar";
// import CountryAutocomplete from "@/components/Utils/country_autocomplete";
import SelectDays from "./SelectDays";
import moment from "moment";
import Dashboard from "../Dashboard.vue";
import LoaderUnicolor from "../Dashboard/LoaderUniColor.vue";
export default {
  components: {
    "toolbar-core": ToolbarCore,
    // "country-autocomplete": CountryAutocomplete,
    "select-days-component": SelectDays,
    "dashboard-new": Dashboard,
    "loader-unicolor": LoaderUnicolor,
  },
  data() {
    return {
      showSelectModule: false,
      dialogEditProfile: false,
      formDialogCount: 0,
      loading: false,
      idUser: "",
      countryUserFlag: "",
      countryUserCode: "",
      name: "",
      last_name: "",
      company: "",
      nickname: "",
      country_id: "",
      phone_number: "",
      old_password: "",
      new_password: "",
      countries: [],
      countries_gtm: [],
      levelUserPercentage: 0,
      country_id_first: {},
      profileData: {},
      isProfile: false,
      moduleId: "",
      levelId: "",
      levels: [],
      modules: [],
      time: null,
      menu2: false,
      days: null,
      showSelectDays: false,
      gtm: "",
      gtm_value: "",
      loadinSelect: false,
    };
  },

  filters: {
    nameTilde(value) {
      return value == "BASICO" ? "BÁSICO" : value;
    },
  },

  created() {
    this.HandlerCountry();
    this.HandlerGmtCountry();
    this.HandlerMe();
    this.HandlerGetListModule();
    this.HandlerGetLevelPercentage();
  },

  watch: {
    moduleId(module_id) {
      this.HandlerListLevels(module_id);
      if (module_id != this.getAuthUser.module_id) {
        this.levelId = "";
      }
    },
  },

  computed: {
    getAuthUser() {
      return this.$store.getters["auth/AUTH_USER"];
    },
  },

  methods: {
    showDialogEdit(value) {
      this.formDialogCount = value;
      this.dialogEditProfile = true;
    },
    async HandlerGetLevelPercentage() {
      try {
        const response1 = await this.$store.dispatch("auth/ME");
        const module_id = response1.data.data.module_id;
        const level = response1.data.data.level;

        const request = { module_id, level };
        const response = await this.$store.dispatch(
          "profile/LEVEL_PERCENTAGE",
          request
        );
        this.levelUserPercentage = response.data.data;
      } catch (error) {
        console.log(error);
        if (error.response.status == 422) {
          let e = error.response.data.error;
          let showNotification = new Notification(e);
          showNotification.notification;
        } else {
          this.$snotify.error(error.response.data.message, "Error!");
        }
      }
    },
    async HandlerCountry() {
      try {
        const response = await this.$store.dispatch("auth/COUNTRIES");
        this.country_id = response.data.data.find((val) => val.id == 238);
        this.country_id_first = response.data.data.find((val) => val.id == 238);
        this.countries = response.data.data;
      } catch (error) {
        console.log("error", error.response);
        this.$snotify.error(
          "Hemos tenido un error obteniendo la informacion de los paises",
          "¡Error!"
        );
      }
    },

    async HandlerGmtCountry() {
      try {
        this.loadinSelect = true;
        const response = await this.$store.dispatch("auth/GMT_COUNTRIES");
        this.countries_gtm = response.data.data;
      } catch (error) {
        console.log("error", error.response);
        this.$snotify.error(
          "Hemos tenido un error obteniendo la informacion de los paises gmt",
          "¡Error!"
        );
      } finally {
        this.loadinSelect = false;
      }
    },

    async HandlerGetListModule() {
      try {
        const response = await this.$store.dispatch("profile/LIST_MODULES");
        this.modules = response.data.data;
      } catch (error) {
        console.log(error);
      }
    },

    async HandlerMe() {
      try {
        const response = await this.$store.dispatch("auth/ME");
        this.HandlerFillField(response);
      } catch (error) {
        console.log("error", error);
        this.$snotify.error(
          "Hemos tenido un error obteniendo la informacion personal",
          "¡Error!"
        );
      }
    },

    HandlerFillField(response) {
      this.idUser = response.data.data.id;
      this.countryUserCode = response.data.data.countries?.phone_code;
      this.countryUserFlag = response.data.data.countries?.flag;
      this.profileData = {
        name: response.data.data.name,
        last_name: response.data.data.last_name,
        company: response.data.data.company.length
          ? response.data.data.company[0].business_name
          : null,
        nickname: response.data.data.nickname,
        country_id: response.data.data.country_id,
        phone_number: response.data.data.phone_number,
      };
      this.moduleId = response.data.data.module_id;
      this.levelId = response.data.data.level;
      this.name = response.data.data.name;
      this.last_name = response.data.data.last_name;
      this.company = response.data.data.company.length
        ? response.data.data.company[0].business_name
        : null;
      this.nickname = response.data.data.nickname;
      this.country_id = response.data.data.country_id;
      this.phone_number = response.data.data.phone_number;
      this.isProfile = true;

      this.time = response.data.data.time;

      this.gtm_value = response.data.data.gtm_name;

      this.gtm = this.countries_gtm.find((index) => {
        return index.name == response.data.data.gtm_name;
      });
    },

    async HandlerListLevels(module_id) {
      try {
        const request = {
          module_id: module_id,
        };
        const response = await this.$store.dispatch(
          "profile/LIST_LEVELS",
          request
        );

        this.levels = response.data.data.map((index) => {
          return {
            name: index,
            name_false: index == "basico" ? "Básico" : index,
          };
        });
      } catch (error) {
        console.log(error);
      }
    },

    async HandlerSaveModule() {
      try {
        const request = {
          module_id: this.moduleId,
          level: this.levelId,
        };
        await this.$store.dispatch("profile/UPDATE_MODULE_LEVEL", request);
        this.$snotify.success(
          "Excelente tu informacion ha sido guardada",
          "¡Exitos!"
        );
        window.dataLayer.push({ event: "curso_hora" });
        this.showSelectModule = !this.showSelectModule;
      } catch (error) {
        if (error.response.status == 422) {
          let e = error.response.data.error;
          let showNotification = new Notification(e);
          showNotification.notification;
        } else {
          this.$snotify.error(error.response.data.message, "Error!");
        }
      }
    },

    async HandlerEditProfile() {
      try {
        const time = moment().format("HH:mm:ss");
        this.country_id =
          typeof this.country_id == "object"
            ? this.country_id.id
            : this.country_id;
        this.loading = true;
        this.menu2 = false;
        const request = {
          name: this.name,
          last_name: this.last_name,
          nickname: this.nickname,
          // country_id: this.country_id,
          phone_number: this.phone_number,
          old_password: this.old_password == "" ? null : this.old_password,
          new_password: this.new_password == "" ? null : this.new_password,
          days:
            this.days == null ? JSON.parse(this.getAuthUser.days) : this.days,
          time: this.time == null ? this.getAuthUser.time : this.time,
          level: this.levelId == "" ? this.getAuthUser.level : this.levelId,
          module_id: this.moduleId,
          time_utc: time,
          gtm: this.gtm.gtm,
          gtm_name: this.gtm.name,
        };

        await this.$store.dispatch("auth/UPDATE_PROFILE", request);
        window.dataLayer.push({ event: "curso_hora" });

        this.$snotify.success("Has actualizado tu información", "¡Excelente!");
        this.old_password = "";
        this.new_password = "";
        this.gtm_value = this.gtm.name;
      } catch (error) {
        if (error.response.status == 422) {
          let e = error.response.data.error;
          let showNotification = new Notification(e);
          showNotification.notification;
        } else {
          this.$snotify.error(error.response.data.message, "Error!");
        }
      } finally {
        this.loading = false;
      }
    },

    HandlerUpdatesModule() {
      this.showSelectModule = true;
    },

    HandlerResponseDays(action, value) {
      if (action == 1) {
        this.days = [...value];
        this.HandlerEditProfile();
      }
      this.showSelectDays = false;
    },

    async HandlerGetPhoto(photo) {
      try {
        this.loading = true;
        const formData = new FormData();
        formData.append("image", photo.target.files[0]);
        // request.image = photo.target.files[0];
        const response = await this.$store.dispatch(
          "profile/UPDATE_PROFILE_PICTURE",
          formData
        );
        console.log(response);
        this.$snotify.success("Tu Imagen ha sido modificada", "Exitos!");
      } catch (error) {
        if (error.response.status == 422) {
          let e = error.response.data.error;
          let showNotification = new Notification(e);
          showNotification.notification;
        } else {
          this.$snotify.error(error.response.data.message, "Error!");
        }
      } finally {
        this.loading = false;
      }
    },

    HandlerGetHours() {
      const content = this.getAuthUser.contents;
      if (content.length > 0) {
        const getTime = content.reduce((acc, crr) => {
          acc += crr.pivot.time;
          return acc;
        }, 0);
        let h = Math.floor(getTime / 3600);
        let m = Math.floor((getTime % 3600) / 60);
        let s = Math.floor((getTime % 3600) % 60);

        let hDisplay = h > 0 ? h + (h == 1 ? " hora " : " horas ") : "";
        let mDisplay = m > 0 ? m + (m == 1 ? " minuto " : " minutos ") : "";
        let sDisplay = s > 0 ? s + (s == 1 ? " segundo" : " segundos") : "";
        return `${hDisplay} ${mDisplay} ${sDisplay}`;
      } else {
        return "No ha visto un contenido";
      }
    },
  },
};
</script>

<style scoped>
.divHrsInnersEditDialog {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.divInsideVDialogEditUSer {
  /* background-color: red; */
  min-height: calc(100vh - 135px);
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
@media (max-width: 425px) {
  .classContainerBodySlot {
    margin-top: 5 rem !important;
  }
}

@media (max-width: 1305px) {
  .containerImage--ext1 {
    flex-direction: column !important;
  }
  .conainerMyData,
  .detailsAccountContainer {
    width: 100% !important;
  }
}

@media (max-width: 1024px) {
  .classContainerBodySlot {
    width: 100% !important;
    padding: 1rem !important;
    margin-left: 0 !important;
  }
  .containerImage--ext1 {
    flex-direction: column !important;
  }
  .conainerMyData,
  .detailsAccountContainer {
    width: 100% !important;
  }
  .containerImage {
    flex-wrap: wrap !important;
    align-items: center !important;
    justify-content: center !important;
  }
  .classMyProfileInfoDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 0 !important;
  }
  .classMyProfileInfoDiv2 {
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
  }
  .classMyProfileInfoDiv3 {
    margin-top: 1rem !important;
    flex-direction: column !important;
  }
  .btnEditProfile2 {
    display: block !important;
    position: unset !important;
  }
  .containerImage {
    flex-direction: column !important;
  }
  .containerData {
    height: auto !important;
  }
}
@media (max-width: 768px) {
  .containerSetExperience_child {
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
    text-align: center !important;
    border-bottom: 1px solid !important;
    padding: 4rem 0 !important;
    width: 100% !important;
    min-width: auto !important;
  }
}

.btnCloseDialogEditProfileContainer {
  display: flex;
  justify-content: flex-end;
}

.classBgWhita {
  background-color: white;
}
.styleesdialogEditProfile {
  /* position: relative; */
  top: 0;
  right: 0;
  background-color: #f6f6f6;
  width: 320px;
  min-height: 100vh;
  padding: 1rem;
  overflow: auto;
}
.classMyProfileInfoDiv3 {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}
.classMyProfileInfoDiv2 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1rem;
}
.saveEdit {
  margin: auto;
  width: 100%;
  margin-top: 2rem;
}
.zoneHour {
  width: 200px !important;
}
.containerNumberFlag {
  border-radius: 16px;
  padding: 0.5rem;
  margin: 0.7rem 0;
  height: 32px;
  font-size: 14px;
  opacity: 1;
  background: #e3eff8 0% 0% no-repeat padding-box;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 1rem;
  width: max-content;
}
.btnEditProfile {
  background: #f6f6f6 0% 0% no-repeat padding-box;
  border-radius: 21px;
  opacity: 1;
  padding: 8px 24px;
  font: normal normal normal 13px/26px Poppins;
  position: absolute;
  top: -0px;
  right: 0px;
}
.btnEditProfile2 {
  background: #f6f6f6 0% 0% no-repeat padding-box;
  border-radius: 21px;
  opacity: 1;
  padding: 8px 24px;
  position: absolute;
  font: normal normal normal 13px/26px Poppins;
  top: 0;
  right: 0;
  margin: 1rem;
}
.containerTitlesBtnMyData2 {
  width: 100%;
  display: flex;
  position: relative;
  padding: 8px 0px;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}
.containerTitlesBtnMyData {
  width: 100%;
  display: flex;
  padding: 8px 0px;
  position: relative;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.conainerMyData {
  width: calc(50% - 8px);
  min-width: 300px;
  overflow: hidden;
  padding: 2rem;
  /* margin-right: 16px; */
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 12px;
  opacity: 1;
}
.title3 {
  text-align: left;
  font-size: 18px;
  font-weight: 600;
  font: normal medium 18px/27px Poppins;
  letter-spacing: 0px;
  color: #101820;
  opacity: 1;
}
.tittleMyProfile2 {
  text-align: left;
  font: normal normal 600 24px/40px Poppins;
  letter-spacing: 0px;
  color: #101820;
}
.titleMyProfile {
  text-align: left;
  font: normal normal 300 17px/40px Poppins;
  letter-spacing: 0px;
  color: #101820;
}
.fontWBold {
  font: normal normal Bold 20px/20px Poppins !important;
  display: flex;
  flex-direction: column;
}
.titleSpan {
  text-align: left;
  font: normal normal normal 13px/20px Poppins;
  letter-spacing: 0px;
  color: #62646a;
}
.columnDatesHeaders {
  width: 250px;
  gap: 1rem;
  margin-top: 1rem;
}
.containerSetExperience_child {
  /* background-color: red; */
  height: 4rem;
  display: flex;
  align-items: center;
  padding-left: 2rem;
  min-width: 700px;
  /* justify-content: center; */
}
.containerSetExperience_child_text {
  margin: 0 1rem;
  width: 240px;
}
.columnDatesValues {
  gap: 1rem;
  margin-top: 1rem;
}
.dividerCustom {
  width: 500%;
  margin-left: -33px;
  background-color: rgba(0, 0, 0, 0.12);
  height: 0.1px;
}
.trCustom {
  height: 5rem;
  margin-bottom: 1rem;
}
.detailAccountTitle {
  text-align: left;
  margin: 1rem 0;
  /* font-size: 18px; */
  font: normal normal 18px/27px Poppins;
  letter-spacing: 0px;
  color: #101820;
}
.trPadding {
  padding-left: 2rem;
}
.alignCenterTr {
  align-items: flex-start;
}
.cellMiddleDinamyc {
  text-align: left;
  font: normal normal normal 14px/22px Poppins;
  letter-spacing: 0px;
  color: #3e4a56;
  opacity: 1;
}
.bg_none {
  background-color: transparent !important;
}
.divPencilRound {
  width: 3rem;
  height: 3rem;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f6f6f6 0% 0% no-repeat padding-box;
}
.detailAccountTitle {
  text-align: left;
  /* margin: 1rem 0; */
  /* font-size: 18px; */
  font: normal normal 18px/27px Poppins;
  letter-spacing: 0px;
  color: #101820;
  padding: 0;
  margin-bottom: 1rem;
  margin-top: 0rem;
  font-weight: bold;
}
.detailsAccountContainer {
  width: calc(50% - 8px);
  min-width: 300px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 12px;
  opacity: 1;
  overflow: hidden;
  /* padding: 7rem; */
  padding: 2rem;
  padding-bottom: 1rem;
  overflow: auto;
}
.containerImage {
  width: 100%;
  /* height: 236px; */
  /* UI Properties */
  background-color: white;
  display: flex;
  position: relative;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 12px;
  padding: 2rem;
}

.setExperience {
  width: 100%;
  /* height: 236px; */
  /* UI Properties */
  background-color: white;
  display: flex;
  flex-direction: column;
  position: relative;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 12px;
  margin-bottom: 2rem;
  overflow: auto;
  /* padding: 2rem; */
}
.setExperience_title {
  text-align: left;
  font-size: 18px;
  font-weight: 600;
  font: normal medium 18px/27px Poppins;
  letter-spacing: 0px;
  color: #101820;
  opacity: 1;
  margin: 1rem;
}
.containerImage--ext1 {
  justify-content: space-between;
  padding: 0px;
  gap: 1rem;
  flex-wrap: wrap;
  margin: 1rem 0;
}
.containerData {
  border: 1px dashed #b9c4cf;
  border-radius: 8px;
  opacity: 1;
  padding: 0.8rem;
}
.containerData__title {
  font-weight: 700;
  text-align: left;
  font-size: 18px;
  /* font: normal normal medium 18px/40px Poppins; */
  letter-spacing: 0px;
  color: #131e29;
}
</style>
