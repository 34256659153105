import { render, staticRenderFns } from "./CardListV2.vue?vue&type=template&id=45193811&scoped=true"
import script from "./CardListV2.vue?vue&type=script&lang=js"
export * from "./CardListV2.vue?vue&type=script&lang=js"
import style0 from "./CardListV2.vue?vue&type=style&index=0&id=45193811&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45193811",
  null
  
)

export default component.exports