<template>
  <div class="container-bg">
    <slot></slot>
  </div>
</template>

<script>
//   import ToolbarCore from "../core/Toolbar";

export default {
  components: {
    //   "toolbar-core": ToolbarCore,
  },

  data() {
    return {
      plan: [],
    };
  },

  methods: {},
};
</script>

<style scoped>
.container-bg {
  /* background: rgb(246, 246, 246);
  min-height: calc(100vh - 300px);
  position: relative;
  top: 0px; */
  background: rgb(246, 246, 246);
  min-height: 100vh;
  width: 100%;
  /* min-height: calc(100vh - 300px); */
  position: absolute;
  top: 0px;
  z-index: 1;
  overflow: auto;
}
</style>
