<template>
  <div class="sidebar-new-left">
    <p class="sidlebar-new-left__text-path">Tu Recorrido</p>
    <loader-new :percentage="levelUserPercentage" />
    <p class="mt-3 sidebar-new-left__text-progress">
      alcanzaste {{ levelUserPercentage }}% de tu recorrido
    </p>
    <div>
      <p class="sidebar-new-left__menu">Menú</p>
    </div>
    <div v-for="(value, key) in childrens" :key="key">
      <button @click="value.redirect">
        <div
          :class="
            value.statusBorder == 1
              ? `sidebar-new-left__items d-flex center borderColoring sidebar-new-left__items_${key}`
              : `sidebar-new-left__items d-flex center sidebar-new-left__items_${key}`
          "
        >
          <div>
            <v-icon :color="value.statusBorder == 1 ? '#0076ff' : ''">{{
              value.icon
            }}</v-icon>
            <span class="ml-2">{{ value.name }}</span>
          </div>
          <v-icon :color="value.statusBorder == 1 ? '#0076ff' : ''"
            >mdi-chevron-right</v-icon
          >
        </div>
      </button>
    </div>
    <!-- 
    <div
      id="sidebar-new-recorrido"
      class="sidebar-new-left__items d-flex center"
    >
      <div>
        <v-icon>mdi-airplane-takeoff</v-icon>
        <span class="ml-2">Mi Recorrido </span>
      </div>
      <v-icon>mdi-chevron-right</v-icon>
    </div>
    <div
      id="sidebar-new-favoritos"
      class="sidebar-new-left__items d-flex center"
    >
      <div>
        <v-icon>mdi-heart-outline</v-icon>
        <span class="ml-2">Favoritos</span>
      </div>
      <v-icon>mdi-chevron-right</v-icon>
    </div>
    <div id="sidebar-new-perfil" class="sidebar-new-left__items d-flex center">
      <div>
        <v-icon>mdi-account-outline</v-icon>
        <span class="ml-2">Mi Perfil</span>
      </div>
      <v-icon>mdi-chevron-right</v-icon>
    </div> -->
  </div>
</template>

<script>
import Loader from "../Dashboard/Loader.vue";

export default {
  components: {
    "loader-new": Loader,
  },
  props: {
    levelUserPercentage: {
      type: String,
      default: "0",
    },
  },
  data() {
    return {
      childrens: [
        {
          name: "Panel",
          icon: "mdi-home-outline",
          path: "/profile",
          redirect: () => {
            this.$router.push({ name: "profile" });
          },
          statusBorder: 0,
        },
        {
          name: "Mi Recorrido",
          icon: "mdi-airplane-takeoff",
          path: "/my_way",
          redirect: () => {
            this.$router.push({ name: "my_way" });
          },
          statusBorder: 0,
        },
        {
          name: "Favoritos",
          icon: "mdi-heart-outline",
          path: "/my_favorites",
          redirect: () => {
            this.$router.push({ name: "my_favorites" });
          },
          statusBorder: 0,
        },
        {
          name: "Histórico",
          icon: "mdi-check-bold",
          path: "/my_history",
          redirect: () => {
            this.$router.push({ name: "my_history" });
          },
          statusBorder: 0,
        },
        {
          name: "Mi Perfil",
          icon: "mdi-account-outline",
          path: "/my_profile",
          redirect: () => {
            this.$router.push({ name: "my_profile" });
          },
          statusBorder: 0,
        },
      ],
    };
  },
  mounted() {
    // console.log(
    //   "window.location.pathname",
    //   this.childrens,
    //   window.location.pathname
    // );
    // this.addClassToDiv();
    this.childrens.forEach((element, index) => {
      if (window.location.pathname === element.path) {
        // console.log("booorder", element, window.location.pathname);
        this.childrens[index].statusBorder = 1;
      }
    });
  },
  methods: {
    // addClassToDiv() {
    //   const classBorderColor = "borderColoring";
    //   if (window.location.pathname === this.route) {
    //     console.log(classBorderColor, this.idDiv);
    //     document.getElementById(this.idDiv).classList.add(classBorderColor);
    //   }
    // },
  },
  computed: {
    getAuthUser() {
      return this.$store.getters["auth/AUTH_USER"];
    },
  },
};
</script>

<style scoped>
@media (max-width: 1024px) {
  .sidebar-new-left__menu,
  .sidebar-new-left__text-progress,
  .sidlebar-new-left__text-path {
    display: none !important;
  }
  .sidebar-new-left {
    display: flex;
    align-items: center !important;
    justify-content: center !important;
    gap: 1rem;
    width: 90% !important;
    height: 150px !important;
    overflow: auto;
    /* margin: 0 1rem !important; */
  }
  .sidebar-new-left__items {
    margin-top: 0 !important;
  }
  /* .sidebar-new-left__items_0 {
    margin-left: 12rem !important;
  } */
}
@media (max-width: 768px) {
  .sidebar-new-left {
    display: none !important;
  }
}
.center {
  align-items: center;
  justify-content: space-around;
}

.borderColoring {
  border: 2px solid #0076ff !important;
  color: #0076ff !important;
}

.classBorderColor > div i.v-icon.v-icon {
  color: red !important;
}
.sidebar-new-left__items {
  margin-top: 1rem;
  width: 205px;
  height: 81px;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  border: 1px solid var(--unnamed-color-dfdfdf);
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dfdfdf;
  border-radius: 8px;
}
.sidebar-new-left__menu {
  font-weight: 700;
  text-align: left;
  font: normal normal medium 18px/40px Poppins;
  letter-spacing: 0px;
  color: #131e29;
}
.sidebar-new-left__text-progress {
  color: #0076ff;
  font: normal normal normal 13px/20px Poppins;
}
.sidlebar-new-left__text-path {
  text-align: left;
  font: normal normal medium 18px/40px Poppins;
  letter-spacing: 0px;
  color: #131e29;
}
.sidebar-new-left {
  width: 275px;
  padding: 2rem;
  height: 670px;
  /* overflow: auto;
  min-height: calc(100vh - 200px); */
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 12px;
}
</style>
