<template>
  <v-dialog v-model="showSelectDays" max-width="700" persistent>
    <v-card>
      <div class="d-flex justify-end pt-2 pr-2">
        <v-btn @click="close" icon x-small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-card-text>
        <v-row justify="center" class="mt-0 pt-10">
          <v-col cols="12" md="12">
            <p
              class="text-center"
              style="color: #929292; font: normal normal normal 18px Montserrat"
            >
              ¡Acá puedes seleccionar los días que quieres aprender!
            </p>
          </v-col>
          <v-col
            v-for="(item, index) in allDays"
            :key="index"
            cols="4"
            md="1"
            class="ml-1 mx-md-1"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-avatar
                  @click="HandlerSelectDay(item)"
                  v-bind="attrs"
                  v-on="on"
                  :color="arrayDays.includes(item) ? '#0DAAC9' : '#061F42'"
                  size="55"
                >
                  <span class="white--text" style="font-size: 18px">
                    {{ item[0] }}
                  </span>
                </v-avatar>
              </template>
              <span>{{ item }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row justify="center" class="mt-15">
          <v-col class="d-flex justify-center" cols="12" md="2">
            <v-btn
              class="text-center"
              @click="HandlerSaveData"
              rounded
              dark
              color="#0DAAC9"
            >
              GUARDAR
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    showSelectDays: {
      type: Boolean,
      default: false,
      required: true,
    },
    selectDays: {
      type: String,
      default: "",
      required: false,
    },
  },
  data() {
    return {
      allDays: [
        "Lunes",
        "Martes",
        "Miércoles",
        "Jueves",
        "Viernes",
        "Sabado",
        "Domingo",
      ],
      arrayDays: [],
    };
  },
  created() {
    const arrayDaysUser = JSON.parse(this.selectDays);
    this.arrayDays = [...arrayDaysUser];
  },
  methods: {
    HandlerSelectDay(day) {
      if (this.arrayDays.length == 0) {
        this.arrayDays.push(day);
      } else if (this.arrayDays.includes(day)) {
        const position = this.arrayDays.indexOf(day);
        this.arrayDays.splice(position, 1);
      } else {
        this.arrayDays.push(day);
      }
    },
    close() {
      this.$emit("dialog:change", 0, null);
    },
    HandlerSaveData() {
      this.$emit("dialog:change", 1, this.arrayDays);
    },
  },
};
</script>

<style>
</style>