<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="12">
        <iframe
          src="https://pilsa.io/conditions.pdf"
          style="height: 100vh; width: 100%"
        >
          <p>Your browser does not support iframes.</p>
        </iframe>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {};
</script>

<style></style>
