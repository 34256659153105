<template>
  <ValidationProvider name="pais" rules="required" v-slot="{ errors }">
    <v-autocomplete
      :disabled="disabled"
      :items="countries"
      :item-text="(item) => item.name + ' ' + item.phone_code"
      :item-value="(item) => item"
      v-model="myProfile"
      outlined
      class="forced"
      :error-messages="errors"
    >
      <template v-slot:[`selection`]="{ item }">
        <v-avatar class="px-0 mr-0" size="20">
          <v-img :src="item.flag"></v-img>
        </v-avatar>
        <span>{{ item.phone_code }}</span>
      </template>
      <template v-slot:[`item`]="{ item }">
        <v-avatar class="mr-2" size="20">
          <v-img :src="item.flag"></v-img>
        </v-avatar>
        <span>{{ item.name }}</span>
      </template>
    </v-autocomplete>
  </ValidationProvider>
</template>

<script>
export default {
  props: {
    small: {
      type: Boolean,
      required: false,
      defalut: false,
    },
    isProfile: {
      type: Boolean,
      required: false,
      defalut: false,
    },
    profile: {
      type: Object,
      required: false,
      defalut: () => {},
    },
    disabled: {
      type: Boolean,
      required: false,
      defalut: false,
    },
  },
  data() {
    return {
      countries: [],
      country_id: "",
    };
  },
  async created() {
    this.HandlerCountry();
  },

  computed: {
    myProfile: {
      get() {
        if (this.isProfile) {
          let country_id = this.countries.find(
            (val) => val.id == this.profile.country_id
          );
          return country_id;
        } else {
          return this.country_id;
        }
      },
      set(val) {
        this.country_id = val;
      },
    },
  },
  watch: {
    country_id(val) {
      this.$emit("dialog:change", val);
    },
  },
  methods: {
    async HandlerCountry() {
      try {
        const response = await this.$store.dispatch("auth/COUNTRIES");
        this.countries = response.data.data;
        const infoByIp = await this.HandlerIpapi();
        this.country_id = infoByIp
          ? response.data.data.find(
              (val) => val.phone_code == infoByIp.country_calling_code
            )
          : response.data.data.find((val) => val.id == 238);
      } catch (error) {
        console.log("error", error.response);
      }
    },

    async HandlerIpapi() {
      try {
        const response = await this.$store.dispatch("auth/IPAPI");
        return response.data;
      } catch (error) {
        console.log("error", error.response);
      }
    },
  },
};
</script>

<style scoped>
.forced {
  box-shadow: none !important;
  /* max-width: 250px; */
}
</style>
